import React from "react";

export default function TableLoading() {
  return (
    <div className=" overflow-x-auto animate-pulse p-4">
      <div className="w-full flex flex-col gap-3">
        <div className="w-full flex justify-between items-center">
          <div className="w-1/3 h-6 bg-gray-700 rounded-lg" />
          {/* <div className="w-[13%] h-12 bg-gray-700 rounded-lg" /> */}
        </div>
        <div className="w-full flex justify-between items-center bg-gray-800 p-2 rounded-lg">
          <div className="w-2/3 flex justify-start items-center gap-2">
            <div className="w-1/4 h-9 bg-gray-700 rounded-lg" />
            <div className="w-1/4 h-9 bg-gray-700 rounded-lg" />
            <div className="w-1/4 h-9 bg-gray-700 rounded-lg" />
          </div>
          <div className="w-[30%] flex justify-start items-center gap-2">
            <div className="w-full h-9 bg-gray-700 rounded-lg" />
            <div className="w-full h-9 bg-gray-700 rounded-lg" />
            <div className="w-full h-9 bg-gray-700 rounded-lg" />
          </div>
        </div>
        <div className="w-full flex flex-col gap-y-5">
          <div className="border-b border-white/10">
            <div className="flex justify-between py-4">
              {Array.from({ length: 7 }, (_, i) => (
                <div scope="col" key={i} className="w-52 flex justify-between">
                  <div className="w-44 h-5 bg-gray-700 rounded-lg" />
                  <div className="w-1 h-6 bg-gray-700" />
                </div>
              ))}
            </div>
          </div>

          <div className="w-[98%] divide-white/5 flex justify-between">
            {Array.from({ length: 7 }, (_, i) => (
              <div key={i * 2} className="flex flex-col gap-5">
                {Array.from({ length: 10 }, (_, i) => (
                  <div key={i * 3}>
                    <div className="w-44 h-5 bg-gray-700 rounded-lg" />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
