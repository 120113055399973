import React, { useState } from "react";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import SuccessModal from "../../components/SuccessModal";

export default function Members() {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  return (
    <div className="p-5 w-full flex flex-col overflow-hidden">
      <div className="w-full flex flex-col justify-center items-center gap-10 ">
        <div className="p-5 w-full flex flex-col overflow-hidden bg-gray-500/10 border border-gray-400/10 rounded-lg">
          <div className="w-full flex justify-end items-center">
            <button
              type="button"
              className="rounded-xl bg-indigo-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 duration-200 ease-in-out"
              onClick={() => setShowModal(true)}
            >
              + New Member
            </button>
          </div>
          <Table
            resKey="members"
            queryKey="organization/users"
            route="organization/users"
            pagination={true}
            select={true}
            tableHeader={true}
            resizableCols={false}
            filterDomains={false}
            actionFilter={false}
            exportFilter={false}
            manageColsFilter={false}
            deleteFilter={true}
            tableTitle={false}
            Status_Filter={false}
            withBG={true}
            rowAction={["edit", "delete"]}
            status={["Active", "InActive"]}
            role={["Admin", "Member"]}
            columns={["full_name", "email", "role", "2fa_status"]}
            tHead={["Full name", "Email", "Role", "2FA status"]}
            fields={[
              {
                label: "Full name",
                name: "full_name",
                type: "fullNameInput",
              },
              {
                label: "Email Address",
                name: "email",
                type: "email",
              },
              {
                options: [
                  { name: "Admin", value: 2 },
                  { name: "Member", value: 3 },
                ],
                name: "role",
                type: "select",
                label: "Role",
              },
            ]}
            modalAdjustment={[{ header: "Update member", primaryBTN: "Save" }]}
            searchBTN={{ options: ["email", "url"] }}
            confirmationModalRoute="organization/member"
            editModalRoute="organization/member-profile"
            showSuccessMsg={showSuccessMsg}
            successMsg={successMsg}
            setShowSuccessMsg={setShowSuccessMsg}
            setSuccessMsg={setSuccessMsg}
            deleteModalMsg="Are you sure you want to delete this member?"
          />
        </div>
      </div>

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        showSuccessMsg={showSuccessMsg}
        setShowSuccessMsg={setShowSuccessMsg}
        setSuccessMsg={setSuccessMsg}
        fields={[
          { label: "Full Name", name: "full_name", type: "fullNameInput" },
          {
            label: "Email address",
            name: "email",
            type: "email",
          },
          {
            options: [
              { name: "Admin", value: 2 },
              { name: "Member", value: 3 },
            ],
            name: "role",
            type: "select",
            label: "Role",
          },
        ]}
        modalAdjustment={[
          { header: "Invite new memeber", primaryBTN: "Send" },
        ]}
        route={"organization/invite-member"}
      />
      <SuccessModal
        show={showSuccessMsg}
        setShow={setShowSuccessMsg}
        message={successMsg}
      />
    </div>
  );
}
