import instance from "../../utils/axios";

const changePassword = async (
  currentPassword,
  newPassword,
  confirmPassword
) => {
  try {
    const res = await instance.post("profile/change-password", {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default changePassword;
