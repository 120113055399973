import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import updateCredentials from "../../apis/mutation/updateStatus";
import ConfirmationModal from "../ConfirmationModal";

export default function DeleteBTN({
  show,
  setShow,
  selectedid,
  setSuccessMsg,
  queryKey,
  confirmationModalRoute,
}) {
  const [showDelModal, setShowDelModal] = useState(false);

  // const queryClient = useQueryClient();

  // const mutation = useMutation({
  //   mutationFn: () => {
  //     return updateCredentials(selectedid);
  //   },
  //   onSuccess: () => {
  //     setShowDelModal(false);
  //     setSuccessMsg("Credentials has been updated successfully");
  //     setShow(!show);
  //     queryClient.invalidateQueries(queryKey);
  //   },
  // });
  return (
    <div
      className={`h-full w-full lg:w-max border border-rose-500 rounded-lg bg-gray-500/10 sm:text-sm ${
        selectedid.length >= 2 ? "flex" : "hidden"
      }`}
    >
      <div
        className="w-full lg:w-max px-3 py-2 flex justify-center lg:justify-between items-center gap-2 text-rose-500  h-full  cursor-pointer"
        onClick={() => {
          setShowDelModal(!showDelModal);
        }}
      >
        <h1 className="leading-[1.3] font-semibold">Delete All</h1>
      </div>

      <ConfirmationModal
        showModal={showDelModal}
        setShowModal={setShowDelModal}
        userId={selectedid}
        route={confirmationModalRoute}
        modalAdjustment={[{ header: "Confirm Delete", primaryBTN: "Delete" }]}
        showSuccessMsg={show}
        setShowSuccessMsg={setShow}
        setSuccessMsg={setSuccessMsg}
      />
    </div>
  );
}
