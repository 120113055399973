import instance from "../../utils/axios";

const updateCredentials = async ({ id, route }) => {
  try {
    let credentials = { credentials: id };

    if (route === "update-credentials") {
      credentials.status = 0;
    } else {
      credentials.mark = 1;
    }

    const res = await instance.post(`${route}`, credentials);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default updateCredentials;
