import instance from "../../utils/axios";

const exportEmployee = async ({ id, type }) => {
  try {
    const res = await instance.post(
      "organization/export-employees-profile",
      { id, type },
      {
        responseType: type === 1 ? "" : "blob",
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default exportEmployee;
