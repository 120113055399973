import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <section>
      <div className="py-8 px-4 mx-auto max-w-screen-xl h-screen flex flex-col justify-center items-center lg:py-16 lg:px-6">
        <div className=" max-w-screen-sm flex flex-col justify-center items-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-[#2563eb]">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold  md:text-4xl ">
            Something's missing.
          </p>
          <p className="mb-4 text-lg font-light text-gray-400">
            Sorry, we can't find that page. You'll find a lot to explore on the
            home page.
          </p>
          <Link
            to="/dashboard"
            className="inline-flex text-white ease-in-out duration-300 bg-[#2563eb] hover:bg-[#1e40af] focus:ring-4 focus:outline-none focus:ring-[#93c5fd] font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
          >
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
}
