import instance from "../../utils/axios";

const updateOrganization = async ({
  organization,
  billingEmail,
  securityEmail,
  enabled,
}) => {
  try {
    const requestData = {
      notification_settings: {
        email: {
          employee_data: enabled.email_space,
          user_data: enabled.email_projects,
        },
        slack: {
          employee_data: enabled.site_user,
          user_data: enabled.site_space,
        },
      },
      name: organization,
      billing_email: billingEmail,
      security_email: securityEmail !== "" ? securityEmail : null,
    };

    const res = await instance.post("organization/update", requestData);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default updateOrganization;
