import React, { useState, useRef, useEffect } from "react";
import { PiCircleNotchBold, PiEye, PiEyeClosed } from "react-icons/pi";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import { useMutation, useQuery, useQueryClient } from "react-query";
import SuccessModal from "../../components/SuccessModal";
import UserInfo from "./userInfo";
import getUserInfo from "./../../apis/query/getUserInfo";
import changePassword from "../../apis/mutation/changePassword";
export default function Profile() {
  const userInfoQuery = useQuery("userInfo", getUserInfo);

  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const [validForm, setValidForm] = useState(false);

  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    has8digit: false,
  });
  const [newPasswordInput, setNewPasswordInput] = useState("");

  const [viewPassword, setViewPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isValid, setIsValid] = useState({
    currentPassword: true,
    newPassword: true,
    confirmPassword: true,
  });
  const [passwordLvl, setPasswordLvl] = useState("");

  const changePasswordButtonRef = useRef(null);

  const PasswordStrength = (newPassword) => {
    if (newPassword.length <= 7 || newPassword.length > 20) {
      return false;
    }

    if (!/[A-Z]/.test(newPassword)) {
      return false;
    }

    if (!/[a-z]/.test(newPassword)) {
      return false;
    }

    if (!/\d/.test(newPassword)) {
      return false;
    }

    // if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword)) {
    //   return false;
    // }

    return true;
  };

  const validatePassword = (newPassword) => {
    // const isValid = PasswordStrength(newPassword);
    setValidate({
      hasLow: /[a-z]/.test(newPassword),
      hasCap: /[A-Z]/.test(newPassword),
      hasNumber: /\d/.test(newPassword),
      has8digit: newPassword.length >= 8,
      hasSpicialChar: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword),
    });
  };

  const changePasswordlvl = () => {
    if (
      validate.has8digit &&
      validate.hasLow &&
      validate.hasCap &&
      validate.hasNumber &&
      validate.hasSpicialChar
    ) {
      setPasswordLvl("Strong Password");
    } else if (validate.has8digit && validate.hasLow && validate.hasCap) {
      setPasswordLvl("Medium Password");
    } else if (validate.has8digit) {
      setPasswordLvl(
        "Weak Password: Password must contain lowercase, uppercase, numbers, and special characters"
      );
    } else {
      setPasswordLvl("Password must be at least 8 characters");
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));

    if (name === "newPassword") {
      setNewPasswordInput(value);
      validatePassword(value);
      changePasswordlvl();
    }

    setIsValid((prevIsValid) => ({
      ...prevIsValid,
      currentPassword: true,
      newPassword: true,
      confirmPassword: true,
    }));
  };

  const isPasswordValid = (newPassword, currentPassword, confirmPassword) => {
    if (newPassword === currentPassword) {
      return false;
    } else if (
      newPassword !== confirmPassword ||
      !PasswordStrength(newPassword)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const { currentPassword, newPassword, confirmPassword } = password;

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      return changePassword(currentPassword, newPassword, confirmPassword);
    },
    onSuccess: (data) => {
      setShowSuccessMsg(!showSuccessMsg);
      setSuccessMsg(data?.message);
      queryClient.invalidateQueries(changePassword);
      setPassword({
        newPassword: "",
        currentPassword: "",
        confirmPassword: "",
      });
      setNewPasswordInput("");
      setPasswordLvl("");
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setIsValid((prevState) => ({
        ...prevState,
        currentPassword: false,
      }));
    },
  });

  const handleSubmit = () => {
    const isPasswordValidResult = isPasswordValid(
      newPassword,
      currentPassword,
      confirmPassword
    );
    setIsValid({
      currentPassword: isPasswordValidResult,
      newPassword: isPasswordValidResult,
      confirmPassword: isPasswordValidResult,
    });
    if (isPasswordValidResult) {
      setLoading(true);
      mutation.mutate();
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && changePasswordButtonRef.current) {
        changePasswordButtonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    if (
      isPasswordValid(
        newPasswordInput,
        password.currentPassword,
        password.confirmPassword
      )
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    passwordLvl,
    newPasswordInput,
    password.currentPassword,
    password.confirmPassword,
  ]);

  return (
    <>
      {userInfoQuery.isLoading ? (
        <Loading />
      ) : (
        <div className="p-5 w-full flex justify-center items-center">
          <div className="w-full lg:w-1/2 mx-auto flex flex-col justify-start items-start text-[#E6E7EA] ">
            {/* Profile */}
            <div className="w-full pt-5 pb-8 flex flex-col items-start justify-start border-b border-gray-300/20 gap-3">
              <h1 className="m-0 text-2xl font-bold text-white">
                Account Details
              </h1>
            </div>
            {/* Profile Picture */}
            <UserInfo data={userInfoQuery.data} queryKey="userInfo" />
            {/* password section */}
            <div className="w-full pt-5 pb-8 flex flex-col items-start justify-start border-b border-gray-300/20 gap-3">
              <div className="w-full flex  flex-col  justify-start items-start gap-3">
                {/* Current Password */}
                <div className="w-full lg:w-[45%]">
                  <div
                    className={`w-full flex items-center gap-2 text-sm font-medium leading-6 ${
                      isValid.currentPassword ? "text-gray-400" : "text-red-400"
                    }`}
                  >
                    <label htmlFor="text" className="block">
                      Current Password
                    </label>
                  </div>
                  <div
                    className={`mt-1.5 w-full relative flex items-center ${
                      isValid.currentPassword
                        ? "text-white"
                        : "text-red-400 animate-wiggle"
                    }`}
                  >
                    <input
                      type={viewPassword.currentPassword ? "text" : "password"}
                      className={`block w-full rounded-md border-0 pr-10 py-2 bg-transparent shadow-sm ring-1 ring-insetplaceholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                        isValid.currentPassword
                          ? "ring-gray-400/10 "
                          : "ring-red-400  "
                      }`}
                      placeholder="Current Password"
                      name="currentPassword"
                      value={password.currentPassword || ""}
                      onChange={handlePasswordChange}
                    />

                    <div className="absolute right-3  duration-150">
                      {viewPassword.currentPassword ? (
                        <PiEye
                          className="cursor-pointer select-none"
                          onClick={() =>
                            setViewPassword((prevState) => ({
                              ...prevState,
                              currentPassword: false,
                            }))
                          }
                        />
                      ) : (
                        <PiEyeClosed
                          className="cursor-pointer select-none"
                          onClick={() =>
                            setViewPassword((prevState) => ({
                              ...prevState,
                              currentPassword: true,
                            }))
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`w-full text-sm text-red-400 animate-wiggle ${
                      isValid.currentPassword ? "hidden" : "block"
                    }`}
                  >
                    <p>
                      {password.currentPassword === ""
                        ? "password cannot be empty"
                        : "Invalid password"}
                    </p>
                  </div>
                </div>

                {/* New Password */}
                <div className="w-full lg:w-[45%]">
                  <div
                    className={`w-full flex items-center gap-2 text-sm font-medium leading-6 ${
                      isValid.newPassword ? "text-gray-400" : "text-red-400"
                    }`}
                  >
                    <label htmlFor="text" className="block">
                      New Password
                    </label>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <div
                      className={`mt-1.5 w-full relative flex items-center gap-2 ${
                        isValid.newPassword
                          ? "text-white"
                          : "text-red-400 animate-wiggle"
                      }`}
                    >
                      <input
                        type={viewPassword.newPassword ? "text" : "password"}
                        className={`block w-full rounded-md border-0 pr-10 py-2 bg-transparent shadow-sm ring-1 ring-insetplaceholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                          isValid.newPassword
                            ? "ring-gray-400/10 "
                            : "ring-red-400  "
                        }`}
                        placeholder="New Password"
                        name="newPassword"
                        value={newPasswordInput || ""}
                        onChange={handlePasswordChange}
                        onBlur={() => {
                          const isValidPassword =
                            PasswordStrength(newPasswordInput);
                          setIsValid((prevIsValid) => ({
                            ...prevIsValid,
                            newPassword: isValidPassword,
                          }));
                          changePasswordlvl();
                        }}
                      />

                      <div className="absolute right-3 duration-150">
                        {viewPassword.newPassword ? (
                          <PiEye
                            className="cursor-pointer select-none"
                            onClick={() =>
                              setViewPassword((prevState) => ({
                                ...prevState,
                                newPassword: false,
                              }))
                            }
                          />
                        ) : (
                          <PiEyeClosed
                            className="cursor-pointer select-none"
                            onClick={() =>
                              setViewPassword((prevState) => ({
                                ...prevState,
                                newPassword: true,
                              }))
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="w-full flex flex-col ">
                      <div className="w-full  bg-[#222529] rounded-lg overflow-hidden">
                        <div
                          className={`block h-2 duration-150 ease-in-out ${
                            passwordLvl === "Strong Password"
                              ? "w-full bg-green-500"
                              : passwordLvl === "Medium Password"
                              ? "w-1/2 bg-orange-500"
                              : passwordLvl ===
                                  "Weak Password: Password must be atleast 8 litter [a-z A-Z], Contain a numbers and spicial characters" ||
                                passwordLvl ===
                                  "Password must be at least 8 characters"
                              ? "w-1/4 bg-red-500"
                              : "w-0"
                          }`}
                        />
                      </div>
                      <p className="text-gray-400 text-xs">{passwordLvl}</p>
                    </div>
                  </div>

                  <div
                    className={`w-full text-sm text-red-400 animate-wiggle ${
                      password.newPassword || mutation.isError
                        ? "block"
                        : "hidden"
                    }`}
                  >
                    <p>
                      {password.newPassword === ""
                        ? "Password cannot be empty"
                        : password.confirmPassword !== password.newPassword
                        ? "Passwords do not match"
                        : password.newPassword === password.currentPassword
                        ? "Password cannot be as same as your current Password"
                        : mutation.isError
                        ? "Invalid password"
                        : ""}
                    </p>
                  </div>
                </div>

                {/* Confirm Password */}
                <div className="w-full lg:w-[45%]">
                  <div
                    className={`w-full flex items-center gap-2 text-sm font-medium leading-6 ${
                      isValid.confirmPassword ? "text-gray-400" : "text-red-400"
                    }`}
                  >
                    <label htmlFor="text" className="block">
                      Confirm Password
                    </label>
                  </div>
                  <div
                    className={`mt-1.5 w-full relative flex items-center ${
                      isValid.confirmPassword
                        ? "text-white"
                        : "text-red-400 animate-wiggle"
                    }`}
                  >
                    <input
                      type={viewPassword.confirmPassword ? "text" : "password"}
                      className={`block w-full rounded-md border-0 pr-10 py-2 bg-transparent shadow-sm ring-1 ring-insetplaceholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                        isValid.confirmPassword
                          ? "ring-gray-400/10 "
                          : "ring-red-400  "
                      }`}
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={password.confirmPassword || ""}
                      onChange={handlePasswordChange}
                    />

                    <div className="absolute right-3  duration-150">
                      {viewPassword.confirmPassword ? (
                        <PiEye
                          className="cursor-pointer select-none"
                          onClick={() =>
                            setViewPassword((prevState) => ({
                              ...prevState,
                              confirmPassword: false,
                            }))
                          }
                        />
                      ) : (
                        <PiEyeClosed
                          className="cursor-pointer select-none"
                          onClick={() =>
                            setViewPassword((prevState) => ({
                              ...prevState,
                              confirmPassword: true,
                            }))
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`w-full text-sm text-red-400 animate-wiggle
                 ${isValid.confirmPassword ? "hidden" : "block"}`}
                  >
                    <p>
                      {password.confirmPassword === ""
                        ? "Password cannot be empty"
                        : password.confirmPassword !== password.newPassword
                        ? "Passwords do not match"
                        : "Invalid password"}
                    </p>
                  </div>
                </div>
              </div>

              <button
                type="button"
                disabled={!validForm}
                className={`inline-flex sm:w-1/5 justify-center rounded-lg bg-indigo-600 px-4 py-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                  !validForm
                    ? "cursor-not-allowed opacity-60"
                    : "hover:bg-indigo-500"
                }
                `}
                onClick={() => handleSubmit()}
                ref={changePasswordButtonRef}
              >
                {mutation.isLoading ? (
                  <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
                ) : (
                  "Update"
                )}
              </button>
            </div>
            {/* Multi-Factor section */}
            <div className="w-full pt-5 pb-8 flex flex-col items-start justify-start border-b border-gray-300/20 gap-3">
              <div className="w-full flex flex-col justify-start items-start gap-1">
                <h1 className="text-[#E3E5E8] text-lg font-medium m-0 ">
                  Multi-Factor Authentication
                </h1>
                <p className="text-[#93999F] text-sm">
                  Multi-Factor Authentication protects your account by adding an
                  additional security step when you log in.
                </p>
              </div>
              <button
                type="button"
                className={`inline-flex sm:w-1/4 justify-center rounded-lg  px-4 py-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 ${
                  userInfoQuery.data.data["2fa_status"] === 1
                    ? "bg-rose-600 focus-visible:outline-rose-600  cursor-not-allowed"
                    : "bg-indigo-600 focus-visible:outline-indigo-600 hover:bg-indigo-500"
                }`}
                onClick={() => {
                  setShowModal(true);
                }}
                disabled={
                  userInfoQuery.data.data["2fa_status"] === 1 ? true : false
                }
              >
                {userInfoQuery.data.data["2fa_status"] === 1
                  ? "Deactivate"
                  : "Configure"}
              </button>
            </div>
          </div>

          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            setShowSecondModal={setShowSecondModal}
            fields={[
              {
                label:
                  "For security purposes, please enter the password for your account:",
                name: "password",
                type: "password",
              },
            ]}
            modalAdjustment={[
              {
                header: "Multi-Factor Authentication",
                primaryBTN:
                  userInfoQuery.data.data["2fa_status"] === 1
                    ? "Deactivate"
                    : "Confirm",
                color: "bg-rose-600 hover:bg-rose-500",
              },
            ]}
            route={"profile/enable-2fa"}
          />
          {showSecondModal && (
            <Modal
              showModal={showSecondModal}
              showSecondModal={showSecondModal}
              setShowModal={setShowSecondModal}
              fields={[
                {
                  label: "Scan the QR code with your authenticator app.",
                  name: "qr_code",
                  type: "image",
                  value: "qr_code",
                },
                {
                  label: "Alternatively, enter this code manually:",
                  name: "input",
                  type: "desableInput",
                  value: "secret_key",
                },
                {
                  label: "recovery Codes:",
                  name: "input",
                  type: "codes",
                  value: "recovery_codes",
                },
                {
                  label:
                    "Enter the code from the authentication app to ensure that everything is working.",
                  name: "code",
                  type: "code_input",
                  header: "Verify Code",
                },
              ]}
              modalAdjustment={[
                {
                  header: "Multi-Factor Authentication",
                  primaryBTN: "VERIVY CODE",
                },
              ]}
              route={"profile/activate-2fa"}
            />
          )}
          {showSuccessMsg && (
            <SuccessModal
              show={showSuccessMsg}
              setShow={setShowSuccessMsg}
              message={successMsg}
            />
          )}
        </div>
      )}
    </>
  );
}
