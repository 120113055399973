import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Members from "./members";
import Settings from "./settings";
import { useQuery } from "react-query";
import Loading from "../../components/Loading";
import PendingInvtations from "./pendingInvtations";
import getOrganization from "../../apis/query/getOrganization";
import NotFound from "../NotFound";
import getOrganizationInvitation from "../../apis/query/getOrganizationInvitation";

export default function Workspace() {
  const location = useLocation();

  const currentPath = location.pathname.split("/").pop();

  const { data: organizationData, isLoading: isOrganizationLoading } = useQuery(
    {
      queryKey: ["getOrganization"],
      queryFn: () => getOrganization("organization"),
    }
  );
  const { data: invitationData, isLoading: isInvitationLoading } = useQuery({
    queryKey: ["list-invitation"],
    queryFn: () => getOrganizationInvitation("organization/list-invitation"),
  });

  const secondaryNavigation = [
    { name: "Settings", href: "" },
    { name: "Members", href: "members" },
    {
      name: "Pending Invitations",
      href: "pending-invitations",
      count: invitationData?.data?.data?.length || "0",
    },
  ];

  const hideHeader = !secondaryNavigation.some(
    (item) => item.href === currentPath || currentPath === "settings"
  );
  return (
    <>
      {isOrganizationLoading || isInvitationLoading ? (
        <Loading />
      ) : (
        <div className="p-5 w-full flex flex-col justify-start items-center">
          {!hideHeader && (
            <header className="w-full border-b border-white/5 ">
              <nav className="flex overflow-x-auto pt-3">
                <ul className="flex min-w-full flex-none gap-x-12 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8">
                  {secondaryNavigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={`/settings/${item.href}`}
                        className={`py-3 bg-transparent flex justify-start items-center ${
                          currentPath === item.href ||
                          (item.href === "" && currentPath === "settings")
                            ? "text-indigo-400 bg-indigo-100/10 border-b-2 border-indigo-400"
                            : ""
                        }`}
                      >
                        {item.name}
                        {item.count && (
                          <span className="ml-1 flex justify-center items-center w-5 h-5 text-xs text-white bg-[#616161] rounded-circle">
                            {item.count}
                          </span>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </header>
          )}

          <div className="w-full ">
            <Routes>
              <Route
                index
                element={<Settings data={organizationData.data} />}
              />
              <Route path="/members" element={<Members />} />
              <Route
                path="/pending-invitations"
                element={<PendingInvtations />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
}
