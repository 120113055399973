import {
  HiOutlineArrowPath,
  HiOutlineArrowTrendingUp,
  HiOutlineGlobeAlt,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi2";
import { TbWorldWww } from "react-icons/tb";
import { PiLinkSimple } from "react-icons/pi";
import { MdOutlinePassword } from "react-icons/md";
import { TbCalendarTime } from "react-icons/tb";
import { IoCheckmarkDone } from "react-icons/io5";
import { CgPassword } from "react-icons/cg";
import { FaUser } from "react-icons/fa6";
import { CiFlag1 } from "react-icons/ci";
import { GrUserAdmin } from "react-icons/gr";
import { FaUserCog } from "react-icons/fa";
import { PiPasswordLight } from "react-icons/pi";
import { BsDatabaseExclamation } from "react-icons/bs";
import { IoIosCall } from "react-icons/io";
import { CiLocationOn } from "react-icons/ci";
import { CgHashtag } from "react-icons/cg";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiSettingsLine } from "react-icons/ri";

const ColIcons = [
  {
    name: "Domain",
    icon: <HiOutlineGlobeAlt />,
  },
  {
    name: "URL",
    icon: <PiLinkSimple />,
  },
  {
    name: "Username",
    icon: <CgPassword />,
  },
  {
    name: "Password",
    icon: <MdOutlinePassword />,
  },
  {
    name: "DeviceID",
    icon: <CgHashtag />,
  },
  {
    name: "Date",
    icon: <TbCalendarTime />,
  },
  {
    name: "Status",
    icon: <IoCheckmarkDone />,
  },
  {
    name: "Subdomain Count",
    icon: <TbWorldWww />,
  },
  {
    name: "Last Scan",
    icon: <HiOutlineArrowPath />,
  },
  {
    name: "Leaks",
    icon: <HiOutlineArrowTrendingUp />,
  },
  {
    name: "Domain Status",
    icon: <IoCheckmarkDone />,
  },

  {
    name: "Email",
    icon: <MdOutlineAlternateEmail />,
  },
  {
    name: "Name",
    icon: <FaUser />,
  },
  {
    name: "Hashed password",
    icon: <PiPasswordLight />,
  },
  {
    name: "LeakSource",
    icon: <BsDatabaseExclamation />,
  },
  {
    name: "IP address",
    icon: <CiLocationOn />,
  },
  {
    name: "Phone number",
    icon: <IoIosCall />,
  },
  {
    name: "Country",
    icon: <CiFlag1 />,
  },

  {
    name: "Full name",
    icon: <FaUser />,
  },
  {
    name: "Role",
    icon: <FaUserCog />,
  },
  {
    name: "2FA status",
    icon: <IoCheckmarkDone />,
  },
  {
    name: "Actions",
    icon: <RiSettingsLine />,
  },
];
export default ColIcons;
