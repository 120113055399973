import React, { useLayoutEffect, useRef, useState } from "react";
import Pagination from "../Pagination";
import FilterBTN from "./filterBTN";
import MultiSelectionBTN from "./multiSelectionBTN";
import SearchBTN from "./searchBTN";
import Actions from "./actions";
import Company from "./company";
import Victims from "./victims";
import Copy from "../Copy";
import { useQuery, useQueryClient } from "react-query";
import getTableData from "../../apis/query/getTableData";
import { HiTrash, HiEllipsisVertical } from "react-icons/hi2";
import { VscDash } from "react-icons/vsc";

import Modal from "../Modal";
import ConfirmationModal from "../ConfirmationModal";
import TableLoading from "../TableLoading";
import Export from "./export";
import ResendModal from "../ResendModal";
import { PiArrowClockwiseBold, PiLinkSimple } from "react-icons/pi";
import EndWith from "./endWith";
import DeleteBTN from "./deleteBTN";
import StatusFilter from "./statusFilter";
import ColIcons from "../../utils/colIcons";
import { Link } from "react-router-dom";

export default function Table({
  select,
  columns,
  tableHeader,
  pagination,
  resizableCols,
  copyCell,
  resKey,
  route,
  queryKey,
  companies,
  filterBTN,
  victimsCountry,
  companyBTN,
  searchBTN,
  tHead,
  rowAction,
  actionFilter,
  fields,
  modalAdjustment,
  status,
  role,
  domainType,
  confirmationModalRoute,
  resendModalRoute,
  editModalRoute,
  exportFilter,
  exportType,
  exportRoute,
  endWithFilter,
  filterDomains,
  manageColsFilter,
  deleteFilter,
  tableTitle,
  tableDesc,
  Status_Filter,
  withBG,

  showSuccessMsg,
  setShowSuccessMsg,
  successMsg,
  setSuccessMsg,
  deleteModalMsg,
}) {
  const checkbox = useRef();
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchType, setSearchType] = useState(searchBTN?.options[0]);
  const [searchValue, setSearchValue] = useState("");
  const [country, setCountry] = useState("");
  const [company, setCompany] = useState(companies ? companies[0] : null);
  const [endWith, setEndWith] = useState("All");
  const [filterStatus, setFilterStatus] = useState(null);
  const [isBookmarked, setBookmarked] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);
  const [columnOrder, setColumnOrder] = useState(() => columns);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState([]);
  const [rowData, setRowlData] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [anyRowSelected, setAnyRowSelected] = useState(false);
  const [actionsVisable, setActionsVisable] = useState(true);
  const [colWidth, setColWidth] = useState(
    new Array(columns.length).fill({ width: 150 })
  );
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const defaultColumns = columnOrder?.map((key) => ({
      [key]: key,
    }));
    return defaultColumns;
  });

  const queryClient = useQueryClient();

  const {
    data: returnVal,
    isPreviousData,
    isLoading: queryLoading,
  } = useQuery({
    queryKey: [queryKey, page],
    queryFn: () => {
      setIsLoading(true);
      return getTableData({
        resKey: resKey,
        domainType: domainType,
        route,
        page,
        perPage,
        // dateFilter: dateFilter === "All time" ? "" : dateFilter,
        dateFilter: dateFilter,

        searchType,
        searchValue: searchValue,
        country: victimsCountry ? country : undefined,
        company: company ? company.id : undefined,
        endWith: endWith ? endWith.domain_name || "all" : undefined,
        filterStatus: filterStatus,
        isBookmarked: isBookmarked ? isBookmarked : false,
      });
    },
    onSuccess: (data) => {
      for (let i = 0; i < data?.data?.length; i++) {
        if (data.data[i]?.role === 3) {
          setActionsVisable(true);
          break;
        }
      }
      setIsLoading(false);
    },
    keepPreviousData: true,
  });

  const data = returnVal;
  const fetchData = ({
    perPageVal = perPage,
    dateValue = dateFilter,
    searchValue = "",
    countryValue = country,
    companyValue = company?.id,
    endWithValue = endWith?.domain_name || "all",
    FilterStatusValue = filterStatus,
    Bookmarked = isBookmarked,
    // isBookmarked = isBookmarked ? isBookmarked : false,
  }) => {
    setPage(1);
    queryClient.prefetchQuery({
      queryKey: [queryKey, 1],
      queryFn: () =>
        getTableData({
          resKey: resKey,
          domainType: domainType,
          route,
          page: 1,
          perPage: perPageVal,
          dateFilter: dateValue === null ? dateValue : dateValue,
          searchValue: searchValue,
          country: countryValue,
          company: companyValue,
          endWith: endWithValue,
          filterStatus:
            FilterStatusValue === null ? FilterStatusValue : FilterStatusValue,
          isBookmarked: Bookmarked,
        }),
    });
  };
  const handleChangePerPage = (perPageVal) => {
    setIsLoading(true);
    setPerPage(perPageVal);
    fetchData({ perPageVal });
  };

  const handleChangeSearch = (searchValue) => {
    setIsLoading(true);
    setSearchValue(searchValue);
    fetchData({ searchValue });
  };

  const handleChangeDate = (dateValue) => {
    setIsLoading(true);
    setDateFilter(dateValue);
    fetchData({ dateValue });
  };

  const handleChangeCountry = (countryValue) => {
    setIsLoading(true);
    setCountry(countryValue);
    fetchData({ countryValue });
  };

  const handleChangeCompany = (companyValue) => {
    setIsLoading(true);
    setCompany(companyValue);
    fetchData({ companyValue: companyValue.id });
  };

  const handleChangeEndWith = (endWithValue) => {
    setIsLoading(true);
    setEndWith(endWithValue);
    fetchData({ endWithValue: endWithValue.domain_name || "all" });
  };

  const handleChangeStatusFilter = (FilterStatusValue, isBookmarked) => {
    setIsLoading(true);
    setFilterStatus(FilterStatusValue);
    fetchData({
      FilterStatusValue: FilterStatusValue,
      Bookmarked: isBookmarked || null,
    });
  };

  // function toggleAll() {
  //   if (selectedRows.length < data?.data?.length) {
  //     setSelectedRows(
  //       // data?.data?.map((item) => item?.role !== 2 && item?.id) || []
  //       (data?.data).filter((item) => item?.role !== 2).map((item) => item?.id)
  //     );
  //     setSelectAllChecked(true);
  //   } else {
  //     setSelectedRows([]);
  //     setSelectAllChecked(false);
  //   }
  //   setAnyRowSelected(!selectAllChecked);
  // }

  function toggleAll() {
    const selectableRows = (data?.data || []).filter(
      (item) => item?.role !== 2
    );

    if (selectedRows.length < selectableRows.length) {
      setSelectedRows(selectableRows.map((item) => item?.id));
      setSelectAllChecked(true);
    } else {
      setSelectedRows([]);
      setSelectAllChecked(false);
    }

    setAnyRowSelected(!selectAllChecked);
  }

  const updateColumnOrder = (newColumnOrder) => {
    setColumnOrder(newColumnOrder);
  };

  useLayoutEffect(() => {
    if (select && checkbox.current) {
      const isIndeterminate =
        selectedRows.length > 0 && selectedRows.length < data?.data?.length;
      setSelectAllChecked(selectedRows.length === data?.data?.length);
      if (checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
      setIndeterminate(isIndeterminate);
    }
  }, [selectedRows]);

  function generateColumnMapping(columns, tHead) {
    const columnMapping = {};

    for (let i = 0; i < columns?.length; i++) {
      columnMapping[columns[i]] = tHead[i];
    }

    return columnMapping;
  }
  const columnMapping = generateColumnMapping(columns, tHead);

  const renderIcon = (colName) => {
    const column = ColIcons.filter((col) => col.name === colName)[0].icon;
    return column;
  };

  function getInitials(fullName) {
    const words = fullName.split(" ");

    const initials = words.map((word) => word[0]).join(" ");

    return initials.toUpperCase();
  }

  const sanitizeLink = (link) => {
    if (
      link.toLowerCase().startsWith("android://") ||
      link.toLowerCase().startsWith("https://") ||
      link.toLowerCase().startsWith("http://")
    ) {
      return link;
    } else {
      return null;
    }
  };
  return (
    <>
      {isLoading ? (
        <TableLoading />
      ) : (
        <div className="w-full flex justify-start items-start">
          <div className="w-full ">
            {tableHeader && (
              <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4  p-4 sm:items-center mb-5">
                {tableTitle && (
                  <div className="w-full flex justify-between items-center">
                    <h1>{tableDesc}</h1>
                  </div>
                )}

                <div
                  className={`w-full  flex flex-col lg:flex-row justify-between p-1 rounded-lg gap-1 ${
                    withBG ? "bg-transparent" : "bg-gray-700/20"
                  }`}
                >
                  <div className="w-auto flex flex-col lg:flex-row gap-1 justify-start items-center">
                    <SearchBTN
                      search={searchValue}
                      setSearch={setSearchValue}
                      searchType={searchType}
                      setSearchType={setSearchType}
                      searchBTN={searchBTN}
                      handleChange={handleChangeSearch}
                    />
                    {filterBTN && (
                      <FilterBTN
                        filterBTN={filterBTN}
                        dateFilter={dateFilter}
                        handleChange={handleChangeDate}
                        route={route}
                      />
                    )}
                    {victimsCountry && (
                      <Victims
                        country={country}
                        handleChange={handleChangeCountry}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                      />
                    )}
                    {filterDomains && (
                      <Company
                        company={company}
                        companyBTN={companyBTN}
                        handleChange={handleChangeCompany}
                        options={companies}
                      />
                    )}
                    {endWithFilter && (
                      <EndWith
                        company={endWith}
                        handleChange={handleChangeEndWith}
                        options={companies}
                      />
                    )}
                    {Status_Filter && (
                      <StatusFilter
                        filterStatus={filterStatus}
                        isBookmarked={isBookmarked}
                        setBookmarked={setBookmarked}
                        handleChange={handleChangeStatusFilter}
                        options={[
                          { value: null },
                          { value: "open" },
                          { value: "closed" },
                        ]}
                      />
                    )}
                  </div>

                  <div className="flex flex-col lg:flex-row justify-end items-center gap-1 rounded-lg ">
                    {actionFilter && (
                      <Actions
                        show={showSuccessMsg}
                        setShow={setShowSuccessMsg}
                        setSuccessMsg={setSuccessMsg}
                        selectedid={selectedRows}
                        queryKey={queryKey}
                      />
                    )}
                    {exportFilter && (
                      <Export
                        exportRoute={exportRoute}
                        exportType={exportType}
                        show={showSuccessMsg}
                        setShow={setShowSuccessMsg}
                        setSuccessMsg={setSuccessMsg}
                      />
                    )}
                    {manageColsFilter && (
                      <MultiSelectionBTN
                        tHead={tHead}
                        data={columns}
                        visibleColumns={visibleColumns}
                        setVisibleColumns={setVisibleColumns}
                        updateColumnOrder={updateColumnOrder}
                      />
                    )}
                    {deleteFilter && (
                      <DeleteBTN
                        show={showSuccessMsg}
                        setShow={setShowSuccessMsg}
                        setSuccessMsg={setSuccessMsg}
                        selectedid={selectedRows}
                        queryKey={queryKey}
                        confirmationModalRoute={confirmationModalRoute}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="w-full overflow-x-auto ">
              <table className="table w-full whitespace-normal text-left relative">
                <thead
                  className={`w-full border-b border-white/10 text-sm leading-6 text-white ${
                    resizableCols || withBG ? "bg-[#24282E]" : "bg-transparent"
                  }`}
                >
                  <tr className="w-full overflow-x-auto ">
                    {select && (
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          ref={checkbox}
                          checked={selectAllChecked}
                          onChange={toggleAll}
                        />
                      </th>
                    )}
                    {visibleColumns?.map((column, columnIndex) => {
                      const columnName = Object.keys(column)[0];
                      const displayColumnName = columnMapping[columnName];
                      return (
                        <th
                          scope="col"
                          className="table-cell py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8 relative"
                          key={columnIndex}
                        >
                          {resizableCols && (
                            <div
                              className="bg-[#ffffff1a] hover:bg-[#ffffff4d] absolute top-0 right-0 h-full w-1 cursor-col-resize select-none"
                              onMouseDown={(e) => {
                                const initialX = e.clientX;
                                const th = e.target.closest("th");
                                const headerCellContainer = th.querySelector(
                                  ".headerCellContainer"
                                );

                                const thWidth = headerCellContainer.offsetWidth;
                                const handleMouseMove = (event) => {
                                  const diffX = event.clientX - initialX;
                                  const newWidth = thWidth + diffX;
                                  setColWidth(
                                    colWidth.map((item, i) => {
                                      if (i === columnIndex)
                                        return { width: newWidth };
                                      else return item;
                                    })
                                  );
                                  headerCellContainer.style.width =
                                    newWidth + "px";
                                };

                                const handleMouseUp = () => {
                                  document.removeEventListener(
                                    "mousemove",
                                    handleMouseMove
                                  );
                                  document.removeEventListener(
                                    "mouseup",
                                    handleMouseUp
                                  );
                                };

                                document.addEventListener(
                                  "mousemove",
                                  handleMouseMove
                                );
                                document.addEventListener(
                                  "mouseup",
                                  handleMouseUp
                                );
                              }}
                            />
                          )}
                          <div
                            className="headerCellContainer flex justify-start items-center gap-2 overflow-hidden min-w-max"
                            style={{
                              width: colWidth[columnIndex].width,
                              minWidth:
                                displayColumnName !== "URL"
                                  ? displayColumnName === "IP address" ||
                                    displayColumnName === "Phone number"
                                    ? ""
                                    : "100%"
                                  : "50px",
                            }}
                          >
                            {renderIcon(displayColumnName)}
                            {displayColumnName}
                          </div>
                        </th>
                      );
                    })}
                    {rowAction && actionsVisable && (
                      <th
                        scope="col"
                        className="table-cell font-semibold "
                        key={"actions"}
                      >
                        <div className="flex justify-start items-center gap-2   ">
                          {renderIcon("Actions")}
                          Actions
                        </div>
                      </th>
                    )}
                  </tr>
                </thead>

                <tbody className="divide-y divide-white/5">
                  {data?.data?.length === 0 ? (
                    <tr>
                      <td colSpan={columns?.length + 1}>
                        <div className="my-5 flex flex-col gap-2 justify-center items-center text-xl ">
                          <img
                            src="../search.svg"
                            alt="unread_message"
                            className="w-24 h-24"
                          />
                          <h1>There's no data to display!</h1>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    data?.data?.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className={`${
                            selectedRows.includes(item.id) && "bg-gray-800"
                          }`}
                        >
                          {select &&
                          route === "organization/users" &&
                          item?.role === 2 ? (
                            <td className="relative px-7 sm:w-12 sm:px-6 ">
                              <div
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 bg-gray-600 flex justify-center items-center
                               text-white font-semibold text-xl opacity-60 cursor-not-allowed"
                              >
                                <VscDash />
                              </div>
                            </td>
                          ) : route === "organization/users" &&
                            item?.role !== 2 ? (
                            <td className="relative px-7 sm:w-12 sm:px-6 ">
                              {selectedRows.includes(item) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                              )}
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600"
                                value={item.id}
                                disabled={item?.role === 2}
                                checked={selectedRows.includes(item.id)}
                                onChange={(e) => {
                                  setSelectedRows((prevSelectedRows) => {
                                    if (e.target.checked) {
                                      return [...prevSelectedRows, item.id];
                                    } else {
                                      return prevSelectedRows.filter(
                                        (id) => id !== item.id
                                      );
                                    }
                                  });
                                }}
                              />
                            </td>
                          ) : (
                            select && (
                              <td className="relative px-7 sm:w-12 sm:px-6 ">
                                {selectedRows.includes(item) && (
                                  <div className="absolute inset-y-0 left-0 w-0.5 bg-red-600" />
                                )}
                                <input
                                  type="checkbox"
                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600"
                                  value={item.id}
                                  disabled={item?.role === 2}
                                  checked={selectedRows.includes(item.id)}
                                  onChange={(e) => {
                                    setSelectedRows((prevSelectedRows) => {
                                      if (e.target.checked) {
                                        return [...prevSelectedRows, item.id];
                                      } else {
                                        return prevSelectedRows.filter(
                                          (id) => id !== item.id
                                        );
                                      }
                                    });
                                  }}
                                />
                              </td>
                            )
                          )}

                          {visibleColumns?.map((column, colIndex) => (
                            <td
                              key={colIndex}
                              className="whitespace-nowrap py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8 text-gray-400 "
                            >
                              {column[Object.keys(column)[0]] ===
                                "domain_status" ||
                              column[Object.keys(column)[0]] === "2fa_status" ||
                              column[Object.keys(column)[0]] ===
                                "invitation_status" ? (
                                <div className="flex items-center justify-center gap-x-2">
                                  <div
                                    className={` flex justify-center items-center gap-x-2 py-1 px-3 rounded-full text-xs ${
                                      item[column[Object.keys(column)[0]]] === 1
                                        ? "bg-[#2b532f]"
                                        : " bg-[#8935377d]"
                                    }`}
                                  >
                                    <div
                                      className={`${
                                        item[column[Object.keys(column)[0]]] ===
                                        1
                                          ? "text-green-400 bg-green-400/10"
                                          : "text-rose-400 bg-rose-400/10"
                                      } flex-none rounded-full p-1`}
                                    >
                                      <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                    </div>

                                    <div className="hidden text-white sm:block  ">
                                      {item[column[Object.keys(column)[0]]] ===
                                      1
                                        ? status[0]
                                        : status[1] ?? (
                                            <span className="px-4 rounded-full text-gray-400 leading-none bg-gray-700">
                                              EMPTY
                                            </span>
                                          )}
                                    </div>
                                  </div>
                                </div>
                              ) : column[Object.keys(column)[0]] ===
                                "status" ? (
                                <div>
                                  <div
                                    className={`flex items-center justify-center gap-x-2 py-1 px-3 rounded-full text-xs  ${
                                      item[column[Object.keys(column)[0]]] === 0
                                        ? "bg-[#2b532f]"
                                        : " bg-[#8935377d]"
                                    }`}
                                  >
                                    <div
                                      className={`${
                                        item[column[Object.keys(column)[0]]] ===
                                        0
                                          ? "text-green-400 bg-green-400/10"
                                          : "text-rose-400 bg-rose-400/10"
                                      } flex-none rounded-full p-1`}
                                    >
                                      <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                    </div>
                                    <div className="hidden text-white sm:block  ">
                                      {item[column[Object.keys(column)[0]]] ===
                                      1
                                        ? status[1]
                                        : status[0] ?? (
                                            <span className="px-4 rounded-full text-gray-400 leading-none bg-gray-700">
                                              EMPTY
                                            </span>
                                          )}
                                    </div>
                                  </div>
                                </div>
                              ) : column[Object.keys(column)[0]] ===
                                "full_name" ? (
                                <div className="flex gap-2 items-center justify-start">
                                  <div className="w-7 h-7 overflow-hidden text-xs rounded-circle bg-[#48494B] flex justify-center items-center">
                                    {getInitials(
                                      item[column[Object.keys(column)[0]]]
                                    )}
                                  </div>
                                  {item[column[Object.keys(column)[0]]]}
                                </div>
                              ) : column[Object.keys(column)[0]] === "role" ? (
                                <div className="flex   justify-start items-center gap-3">
                                  {item[column[Object.keys(column)[0]]] === 2
                                    ? role[0]
                                    : role[1] ?? (
                                        <span className="px-4 rounded-full text-gray-400 leading-none bg-gray-700">
                                          EMPTY
                                        </span>
                                      )}
                                </div>
                              ) : column[Object.keys(column)[0]] ===
                                "country" ? (
                                <div className="flex justify-center items-center ">
                                  {item[column[Object.keys(column)[0]]]}
                                </div>
                              ) : column[Object.keys(column)[0]] === "url" ? (
                                <div className="flex justify-between items-center gap-5 ">
                                  <div
                                    // className="text-ellipsis overflow-hidden whitespace-nowrap"
                                    style={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      width: colWidth[colIndex]?.width,
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    {/* {item[column[Object.keys(column)[0]]]
                                      ?.length > 30
                                      ? item[
                                          column[Object.keys(column)[0]]
                                        ].substring(
                                          0,
                                          colWidth[colIndex]?.width / 7 > 30
                                            ? colWidth[colIndex]?.width / 7
                                            : 30
                                        ) + "..."
                                      : item[column[Object.keys(column)[0]]]} */}
                                    {item[column[Object.keys(column)[0]]]}
                                  </div>
                                  <div className="flex justify-end items-center gap-3">
                                    <Link
                                      // to={item[column[Object.keys(column)[0]]]}
                                      to={sanitizeLink(
                                        item[column[Object.keys(column)[0]]]
                                      )}
                                      target="_blank"
                                    >
                                      <PiLinkSimple />
                                    </Link>
                                    <Copy
                                      data={
                                        item[column[Object.keys(column)[0]]]
                                      }
                                    />
                                  </div>
                                </div>
                              ) : column[Object.keys(column)[0]] ===
                                  "hashed_password" &&
                                item[column[Object.keys(column)[0]]] !== "" ? (
                                <div className="flex justify-between items-center">
                                  <div>
                                    {item[column[Object.keys(column)[0]]]
                                      ?.length > 30
                                      ? item[
                                          column[Object.keys(column)[0]]
                                        ].substring(0, 30) + "..."
                                      : item[column[Object.keys(column)[0]]]}
                                  </div>
                                  <Copy
                                    data={item[column[Object.keys(column)[0]]]}
                                  />
                                </div>
                              ) : copyCell?.includes(
                                  column[Object.keys(column)[0]]
                                ) &&
                                item[column[Object.keys(column)[0]]] !== "" ? (
                                <div className="flex justify-between items-center  ">
                                  {item[column[Object.keys(column)[0]]] ===
                                    null ||
                                  item[column[Object.keys(column)[0]]] ===
                                    "" ? (
                                    <span className="px-4 rounded-full text-gray-400 leading-none bg-gray-700">
                                      EMPTY
                                    </span>
                                  ) : (
                                    item[column[Object.keys(column)[0]]]
                                  )}
                                  <Copy
                                    data={item[column[Object.keys(column)[0]]]}
                                  />
                                </div>
                              ) : item[column[Object.keys(column)[0]]] ===
                                  null ||
                                item[column[Object.keys(column)[0]]] === "" ? (
                                <span className="px-4 rounded-full text-gray-400 leading-none bg-gray-700">
                                  EMPTY
                                </span>
                              ) : (
                                <div className="headerCellContainer">
                                  {item[column[Object.keys(column)[0]]]}
                                </div>
                              )}
                            </td>
                          ))}

                          {rowAction && actionsVisable && (
                            <td>
                              <div className="flex gap-2">
                                {rowAction.includes("edit") &&
                                  item?.role !== 2 && (
                                    <div
                                      className="p-1 bg-transparent hover:bg-gray-500/10 rounded-circle cursor-pointer"
                                      onClick={() => {
                                        const selectedItem = data.data.find(
                                          (dataItem) => dataItem.id === item.id
                                        );

                                        const { id, role, email, full_name } =
                                          selectedItem;

                                        const selectedData = {
                                          id,
                                          role,
                                          email,
                                          full_name,
                                        };

                                        setRowlData(selectedData);
                                        setShowEditModal(true);
                                      }}
                                    >
                                      <HiEllipsisVertical />
                                    </div>
                                  )}

                                {rowAction.includes("resend") && (
                                  <div
                                    className="p-1 bg-transparent hover:bg-gray-500/10 rounded-circle cursor-pointer"
                                    onClick={() => {
                                      setUserId(item.id);
                                      setShowResendModal(true);
                                    }}
                                  >
                                    <PiArrowClockwiseBold className="cursor-pointer" />
                                  </div>
                                )}
                                {((rowAction.includes("delete") &&
                                  item?.role !== 2) ||
                                  item.invitation_status === 0) && (
                                  <div
                                    className="p-1 bg-transparent hover:bg-gray-500/10 rounded-circle cursor-pointer"
                                    onClick={() => {
                                      setUserId([item.id]);
                                      setShowDelModal(true);
                                    }}
                                  >
                                    <HiTrash className="cursor-pointer" />
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>

            {rowAction && (
              <>
                <Modal
                  showModal={showEditModal}
                  setShowModal={setShowEditModal}
                  fields={fields}
                  modalAdjustment={modalAdjustment}
                  rowData={rowData}
                  route={editModalRoute}
                  showSuccessMsg={showSuccessMsg}
                  setShowSuccessMsg={setShowSuccessMsg}
                  setSuccessMsg={setSuccessMsg}
                />

                <ConfirmationModal
                  showModal={showDelModal}
                  setShowModal={setShowDelModal}
                  userId={userId}
                  setUserId={setUserId}
                  route={confirmationModalRoute}
                  modalAdjustment={[
                    { header: "Confirm Delete", primaryBTN: "Delete" },
                  ]}
                  showSuccessMsg={showSuccessMsg}
                  setShowSuccessMsg={setShowSuccessMsg}
                  setSuccessMsg={setSuccessMsg}
                  deleteModalMsg={deleteModalMsg}
                />

                <ResendModal
                  showModal={showResendModal}
                  setShowModal={setShowResendModal}
                  userId={userId}
                  setUserId={setUserId}
                  route={resendModalRoute}
                  modalAdjustment={[
                    { header: "Confirm Resend", primaryBTN: "Resend" },
                  ]}
                  showSuccessMsg={showSuccessMsg}
                  setShowSuccessMsg={setShowSuccessMsg}
                  setSuccessMsg={setSuccessMsg}
                />
              </>
            )}
          </div>
        </div>
      )}

      {pagination && data && (
        <Pagination
          setPage={setPage}
          current_page={data?.current_page || 1}
          perPage={perPage}
          total={data?.total}
          searchValue={searchValue}
          handleChange={handleChangePerPage}
        />
      )}
    </>
  );
}
