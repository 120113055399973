import React, { useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";

export default function Copy({ data }) {
  const [copy, setCopy] = useState(null);
  setTimeout(() => {
    setCopy(null);
  }, 1000);

  const hundleCopy = () => {
    setCopy(navigator.clipboard.writeText(data));
  };
  return (
    <div onClick={hundleCopy} className="relative cursor-pointer ml-3">
      <div className="relative flex justify-center">
        <div
          className={`absolute h-8 w-20 p-1 -top-10 bg-[#252B48] rounded-lg text-white flex items-center justify-center text-xs font-normal z-[3] ${
            copy == null ? "hidden" : "block"
          }`}
        >
          <p>Copied</p>
        </div>
        <div
          className={`absolute w-2 h-0 -top-3 z-[2] ${
            copy == null ? "hidden" : "block"
          }`}
          style={{
            borderTop: "10px solid #252B48",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
          }}
        />
        <MdOutlineContentCopy className="text-lg" />
      </div>
    </div>
  );
}
