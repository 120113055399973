import React, { useMemo, useCallback } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import ReactPaginate from "react-paginate";

function Pagination({ setPage, perPage, total, current_page, handleChange }) {
  const pageCount = useMemo(() => Math.ceil(total / perPage), [total, perPage]);

  const handlePageClick = useCallback(
    (e) => {
      setPage(e.selected + 1);
    },
    [setPage]
  );
  return (
    <div className="flex flex-col items-center">
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <HiChevronRight className="inline-block" aria-hidden="true" />
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={
          <HiChevronLeft className="inline-block" aria-hidden="true" />
        }
        forcePage={current_page - 1}
        renderOnZeroPageCount={null}
        containerClassName="isolate inline-flex -space-x-px items-end rounded-md shadow-sm bg-transparent mt-12 rounded-md text-sm font-medium text-gray-700 py-2"
        pageLinkClassName="ring-1 ring-inset ring-gray-600 hover:bg-indigo-600 hover:text-white px-4 py-2 text-sm font-semibold text-white "
        previousLinkClassName="rounded-s-md ring-1 ring-inset ring-gray-600 hover:bg-indigo-600 hover:text-white p-2 text-white "
        nextLinkClassName="rounded-e-md ring-1 ring-inset ring-gray-600 hover:bg-indigo-600 hover:text-white px-2 py-2 text-sm font-semibold text-white"
        activeLinkClassName="bg-indigo-600 p-2"
        breakLinkClassName="px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-600"
      />

      <div className="w-full py-5 flex justify-center items-center gap-2">
        <p className="text-gray-500  font-medium">Rows per page</p>
        <select
          className="bg-gray-600/10 cursor-pointer rounded-xl border-gray-400/10"
          onChange={(e) => handleChange(parseInt(e.target.value))}
          value={perPage}
        >
          <option className="bg-gray-700 text-white font-semibold" value="10">
            10
          </option>
          <option className="bg-gray-700 text-white font-semibold" value="25">
            25
          </option>
          <option className="bg-gray-700 text-white font-semibold" value="50">
            50
          </option>
          <option className="bg-gray-700 text-white font-semibold" value="100">
            100
          </option>
        </select>
        <p>{`1-${perPage}`}</p>
        <p className="text-gray-500 font-medium">of</p>
        <p>{total}</p>
      </div>
    </div>
  );
}

export default Pagination;
