import instance from "../../utils/axios";

const getTableData = async ({
  resKey,
  route,
  page = 1,
  perPage = "",
  dateFilter = "",
  searchValue = "",
  country = "",
  company,
  domainType,
  endWith,
  filterStatus,
  isBookmarked,
}) => {
  try {
    const queryParams = {
      page,
      perPage,
    };

    if (searchValue) queryParams.q = searchValue;
    if (country) queryParams.country = country;
    if (company !== "") queryParams.domain = company;
    if (dateFilter) queryParams.date = dateFilter;
    if (endWith) queryParams.end_with = endWith;

    if (filterStatus) queryParams.status = filterStatus;

    if (isBookmarked) queryParams.bookmarked = 1;

    const url = `${route}?${new URLSearchParams(queryParams).toString()}`;

    const res = await instance.get(url);

    const { data, current_page, last_page, total } = res.data.data[resKey];

    let filteredData = data;

    if (domainType && data) {
      filteredData = data.filter((domain) => domain.domain_type === domainType);
    }
    return {
      data: filteredData,
      current_page,
      last_page,
      total,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getTableData;
