import React, { useEffect, useRef, useState } from "react";
import { HiTemplate } from "react-icons/hi";
import { FaCheck } from "react-icons/fa";
import { useAuth } from "../../context/authProvider";

export default function MultiSelectionBTN({
  data,
  visibleColumns,
  setVisibleColumns,
  updateColumnOrder,
  tHead,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const { setTableHeader } = useAuth();

  const [value, setValue] = useState(
    visibleColumns.map((col) => Object.keys(col)[0])
  );

  const menuRef7 = useRef(null);
  const filterRef7 = useRef(null);

  const toggleOption = (optionName) => {
    if (value.includes(optionName)) {
      setValue(value.filter((name) => name !== optionName));
      setVisibleColumns((prevVisibleColumns) =>
        prevVisibleColumns.filter((col) => Object.keys(col)[0] !== optionName)
      );
    } else {
      const columnIndex = data.indexOf(optionName);
      const updatedVisibleColumns = [...visibleColumns];
      updatedVisibleColumns.splice(columnIndex, 0, {
        [optionName]: optionName,
      });

      setValue([...value, optionName]);
      setVisibleColumns(updatedVisibleColumns);
      updateColumnOrder(
        updatedVisibleColumns.map((col) => Object.keys(col)[0])
      );
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (!filterRef7.current || !filterRef7.current.contains(event.target)) &&
        (!menuRef7.current || !menuRef7.current.contains(event.target))
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="w-full relative border border-gray-500 justify-center flex items-center h-full bg-gray-500/10 rounded-lg text-white sm:text-sm"
      ref={filterRef7}
    >
      <button
        className="w-max h-full px-3 py-2 rounded-xl flex items-center justify-center gap-3 "
        onClick={() => {
          setTableHeader(7);
          setShowOptions(!showOptions);
        }}
      >
        <HiTemplate className="text-[1.3rem]" />
      </button>
      <div
        className={`absolute w-full lg:w-[15vw] right-0 top-12 lg:top-14 z-10 p-2 border border-gray-500 flex-col justify-start items-start bg-gray-800 rounded-xl text-white sm:text-sm ${
          showOptions ? "flex" : "hidden"
        }`}
        ref={menuRef7}
      >
        <h1 className="text-base text-gray-500 font-semibold">Columns</h1>

        {data.map((header, i) => (
          <button
            key={header}
            className="w-full flex items-center gap-3 py-3 border-0 bg-transparent hover:bg-slate-500/10"
            onClick={() => {
              toggleOption(header);
            }}
          >
            <div
              className={`w-5 h-5 rounded-md flex items-center justify-center border-[2px] ${
                value.includes(header)
                  ? "bg-indigo-600 border-indigo-600"
                  : "bg-gray-700 border-gray-600"
              }`}
            >
              <FaCheck
                className={`text-sm font-semibold ${
                  value.includes(header) ? "block" : "hidden"
                }`}
              />
            </div>
            <h1 className="text-sm font-medium m-0">{tHead[i]}</h1>
          </button>
        ))}
      </div>
    </div>
  );
}
