import React, { useState } from "react";
import { useQueryClient, useQuery } from "react-query";
import getEmployeesProfiles from "../../apis/query/getEmployeesProfiles";
import EmployeesLoading from "../../components/EmployeesLoading";
import Pagination from "../../components/Pagination";
import Export from "./export";
import SuccessModal from "../../components/SuccessModal";
import EmptyEmployees from "../../components/EmptyEmployees";

export default function HackedEmployee() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["EmployeesProfiles", page],
    queryFn: () => getEmployeesProfiles({ page, perPage }),
    keepPreviousData: true,
  });

  const fetchData = ({ perPageVal = perPage }) => {
    queryClient.prefetchQuery({
      queryKey: ["EmployeesProfiles", page],
      queryFn: () =>
        getEmployeesProfiles({
          page,
          perPage: perPageVal,
        }),
    });
  };

  const handleChangePerPage = (perPageVal) => {
    setPerPage(perPageVal);
    fetchData({ perPageVal });
  };

  return (
    <>
      {isLoading || isFetching ? (
        <EmployeesLoading />
      ) : data.data.data.length > 0 ? (
        <div className="p-5 w-full">
          <div className="w-full max-w-[1880px] mx-auto flex flex-wrap gap-5 justify-start items-start">
            {data.data.data.length > 0 ? (
              data.data.data.map((e) => (
                <div
                  key={e.id}
                  className="w-full lg:w-[32%] flex flex-col bg-gray-500/10 justify-center items-start px-5 py-3 rounded-lg gap-2"
                >
                  <div className="w-full flex justify-between items-center text-xl font-bold">
                    <h1 className="m-0 capitalize">{e.employee_name}</h1>
                    <div>
                      <Export
                        show={showSuccessMsg}
                        setShow={setShowSuccessMsg}
                        setSuccessMsg={setSuccessMsg}
                        id={e.id}
                        employeeName={e.employee_name}
                        url={e.s3_path}
                        s3={e.s3_export}
                      />
                    </div>
                  </div>
                  <p className="m-0 text-gray-400 text-sm font-medium capitalize">
                    {e.employee_title}
                  </p>
                  <p className="m-0 text-gray-400 text-sm font-medium">
                    {e.email}
                  </p>
                  <p className="m-0 text-gray-400 text-sm font-medium">
                    <span className="text-white">Compromised date:</span>{" "}
                    {e.compromised_date}
                  </p>
                  <p
                    className={`m-0 text-gray-400 text-sm font-medium ${
                      e.credentials_num > 0 ? "text-rose-500" : "text-green-500"
                    }`}
                  >
                    <span className="text-white text-sm">
                      Total Credentials:
                    </span>{" "}
                    {e.credentials_num}
                  </p>
                </div>
              ))
            ) : (
              <div className="w-full flex flex-col justify-center items-center p-5 text-xl">
                <img
                  src="SecurityOn.svg"
                  alt="SecurityOn"
                  className="w-1/3 opacity-60"
                />
                <h1>There's no data found...</h1>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="h-[70vh]">
          <EmptyEmployees />
        </div>
      )}

      {showSuccessMsg && (
        <SuccessModal
          show={showSuccessMsg}
          setShow={setShowSuccessMsg}
          message={successMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}

      {data && data?.data?.total !== 0 && data?.data?.total > 10 && (
        <Pagination
          setPage={setPage}
          perPage={perPage}
          current_page={data?.data?.current_page || 1}
          total={data?.data?.total}
          handleChange={handleChangePerPage}
        />
      )}
    </>
  );
}
