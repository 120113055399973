import React, { useState, useEffect, useRef } from "react";
import { Switch } from "@headlessui/react";
import { useMutation, useQueryClient } from "react-query";
import updateOrganization from "../../apis/mutation/updateOrganization";
import SuccessModal from "./../../components/SuccessModal/index";
import { PiCircleNotchBold } from "react-icons/pi";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlineContentCopy } from "react-icons/md";
import GenerateAPI from "../../apis/mutation/generateAPI";

export default function Settings(data) {
  const initialEnabledState = {
    email_space: data?.data?.notification_settings?.email?.employee_data,
    email_projects: data?.data?.notification_settings?.email?.user_data,
    site_user: data?.data?.notification_settings?.slack?.employee_data,
    site_space: data?.data?.notification_settings?.slack?.user_data,
  };

  const [enabled, setEnabled] = useState(initialEnabledState);
  const [organization, setOrganization] = useState(data?.data?.name);
  const [securityEmail, setSecurityEmail] = useState(
    data?.data?.security_email
  );
  const [billingEmail, setBillingEmail] = useState(data?.data?.billing_email);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [generatedKey, setGeneratedKey] = useState(null);

  const saveButtonRef = useRef(null);

  const handleSwitchChange = (name) => {
    setEnabled((prevEnabled) => ({
      ...prevEnabled,
      [name]: !prevEnabled[name],
    }));
    setDisabled(false);
  };

  const notifications = [
    {
      header: "Email notifications",
      switches: [
        {
          name: "email_space",
          title: "Employees",
          desc: "We will send you email notifications each time a new employee experiences a credential compromise",
        },
        {
          name: "email_projects",
          title: "Users",
          desc: "We will send you email notifications each time a new user experiences a credential compromise",
        },
      ],
    },
    {
      header: "Slack integrations",
      switches: [
        {
          name: "site_user",
          title: "Employees",
          desc: "We will send slack notifications each time a new employee experiences a credential compromise",
        },
        {
          name: "site_space",
          title: "Users",
          desc: "We will send slack notifications each time a new user experiences a credential compromise",
        },
      ],
    },
  ];

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      return updateOrganization({
        organization,
        billingEmail,
        securityEmail,
        enabled,
      });
    },
    onSuccess: () => {
      setShowSuccessMsg(!showSuccessMsg);
      queryClient.invalidateQueries("updateOrganization");
      setDisabled(true);
    },
  });

  const generate = useMutation({
    mutationFn: () => {
      return GenerateAPI();
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("GenerateAPI");
      setGeneratedKey(data.data.api_key);
    },
  });

  const handleSubmit = () => {
    !disabled && mutation.mutate();
  };
  const handleGenerate = () => {
    generate.mutate();
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && saveButtonRef.current) {
        saveButtonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const [copy, setCopy] = useState(null);
  setTimeout(() => {
    setCopy(null);
  }, 1000);

  const hundleCopy = () => {
    setCopy(navigator.clipboard.writeText(generatedKey));
  };

  return (
    <div className="p-5 w-full lg:w-1/2 mx-auto text-[#cecece] flex flex-col justify-start items-start gap-11 ">
      {/* Organization Settings section*/}
      <div className="w-full flex flex-col justify-start items-start gap-5 border-b border-gray-300/20">
        <div className="w-full mb-7 pb-7 flex flex-col items-start justify-start gap-3 border-b border-gray-300/20 ">
          <h1 className="m-0 text-2xl font-bold text-white">
            Organization settings
          </h1>
          <p className="m-0">
            Update the organization information or notifications settings
          </p>
        </div>

        <div className="w-full flex flex-nowrap lg:flex-wrap flex-col lg:flex-row justify-start items-start gap-3">
          {/* Organization */}
          <div className="w-full lg:w-[45%]">
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              Organization
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-2 bg-transparent text-white shadow-sm ring-1 ring-inset ring-gray-400/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Organization"
                value={organization}
                onChange={(e) => {
                  setOrganization(e.target.value);
                  setDisabled(false);
                }}
              />
            </div>
          </div>

          {/* Security Email */}
          <div className="w-full lg:w-[45%]">
            <label
              htmlFor="text"
              className="flex justify-start items-center gap-3 text-sm font-medium leading-6 text-gray-400 relative "
            >
              Security Email
              <div className="group cursor-pointer">
                <FiAlertCircle />
                <div className="w-full absolute hidden left-0 2xl:left-10 bottom-7 bg-[#17191C] border border-[#414452] p-2 rounded-xl text-gray-400 text-sm text-center font-medium leading-6 group-hover:block">
                  <p>
                    The last time this key was used. Accurate to within the hour
                    from the most recent usage..
                  </p>
                </div>
              </div>
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                className={`block w-full rounded-md border-0 py-2 bg-transparent shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${
                  errors.security_email
                    ? "text-red-500 ring-red-500 animate-wiggle focus:ring-red-600"
                    : "text-white ring-gray-400/10 focus:ring-indigo-600"
                }`}
                placeholder="Security Team Email Address"
                value={securityEmail || ""}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    security_email: null,
                  });
                  setSecurityEmail(e.target.value);
                  setDisabled(false);
                }}
                onBlur={(e) => {
                  const emailPattern =
                    /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                  const inputValue = e.target.value.trim();

                  if (inputValue !== "" && !emailPattern.test(inputValue)) {
                    setErrors({
                      ...errors,
                      security_email: "Please enter a valid email address",
                    });
                    setDisabled(true);
                  } else {
                    setErrors({
                      ...errors,
                      security_email: null,
                    });
                    setDisabled(false);
                  }
                }}
              />
              {errors.security_email !== null && (
                <p className="text-red-500 text-sm animate-wiggle">
                  {errors.security_email}
                </p>
              )}
            </div>
          </div>

          {/* Billing Email Address */}
          <div className="w-full lg:w-[45%]">
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              Billing Email Address
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                className={`block w-full rounded-md border-0 py-2 bg-transparent shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${
                  errors.billing_email
                    ? "text-red-500 ring-red-500 animate-wiggle focus:ring-red-600"
                    : "text-white ring-gray-400/10 focus:ring-indigo-600"
                }`}
                placeholder="Billing Email Address"
                value={billingEmail || ""}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    billing_email: null,
                  });
                  setBillingEmail(e.target.value);
                  setDisabled(false);
                }}
                onBlur={(e) => {
                  const emailPattern =
                    /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                  if (!emailPattern.test(e.target.value)) {
                    setErrors({
                      ...errors,
                      billing_email: "Please enter a valid email address",
                    });
                    setDisabled(true);
                  } else {
                    setErrors({
                      ...errors,
                      billing_email: null,
                    });
                    setDisabled(false);
                  }
                }}
              />
              {errors.billing_email !== null && (
                <p className="text-red-500 text-sm animate-wiggle">
                  {errors.billing_email}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="w-full pb-11 flex justify-center items-center">
          <button
            type="button"
            className={`inline-flex sm:w-1/5 justify-center rounded-lg px-4 py-3 text-sm bg-indigo-600 font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
              disabled ? "cursor-not-allowed opacity-60" : "hover:bg-indigo-500"
            }`}
            disabled={disabled}
            onClick={handleSubmit}
            ref={saveButtonRef}
          >
            {mutation.isLoading ? (
              <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>

      {/* Generate API Key section */}
      <div className="w-full flex flex-col justify-start items-start gap-5 pb-7 border-b border-gray-300/20">
        <div className="w-full mb-7 pb-7 flex flex-col items-start justify-start gap-3 border-b border-gray-300/20 ">
          <h1 className="m-0 text-3xl font-bold text-white">
            Generate API Key
          </h1>
          <p className="m-0">
            Generate a secure API key to authenticate and access our services.
            Keep this key confidential to protect your account and data.
          </p>
        </div>

        {generatedKey && (
          <div className="w-full py-2 px-3 bg-gray-400/10 border border-gray-700 flex justify-between items-start rounded-lg text-sm">
            <p>{generatedKey}</p>

            <div onClick={hundleCopy} className="relative cursor-pointer ml-3">
              <div className="relative flex justify-center">
                <div
                  className={`absolute select-none h-8 w-20 p-1 -top-10 bg-[#4f5988] rounded-lg text-white flex items-center justify-center text-xs font-normal z-[3] ${
                    copy == null ? "hidden" : "block"
                  }`}
                >
                  <p>Copied</p>
                </div>
                <div
                  className={`absolute w-2 h-0 -top-3 z-[2] ${
                    copy == null ? "hidden" : "block"
                  }`}
                  style={{
                    borderTop: "10px solid #4f5988",
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                  }}
                />
                <MdOutlineContentCopy className="text-lg" />
              </div>
            </div>
          </div>
        )}

        <div className="w-full flex items-center justify-center">
          <button
            type="button"
            className="inline-flex sm:w-1/5 justify-center rounded-lg px-4 py-3 text-sm bg-indigo-600 font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 hover:bg-indigo-500 cursor-pointer"
            onClick={handleGenerate}
          >
            {generate.isLoading ? (
              <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
            ) : (
              "Generate"
            )}
          </button>
        </div>
      </div>

      {/* Notifications section */}
      <div className="w-full flex flex-col justify-start items-start gap-12">
        <div className="w-full flex flex-col items-start justify-start gap-1 ">
          <h1 className="m-0 text-3xl font-bold text-white">Notifications</h1>
          <p className="m-0 text-[16px]">
            Customize how you can receive notifications when we identify new
            compromised credentials or breaches
          </p>
        </div>

        {notifications.map((item, i) => (
          <div
            key={i}
            className="w-full flex flex-col justify-start items-start gap-5"
          >
            <div className="w-full py-2 px-3 bg-gray-400/10 border border-gray-700 flex justify-start items-center rounded-lg">
              <h1 className=" text-lg font-semibold text-white">
                {item.header}
              </h1>
            </div>
            {item.switches.map((row) => (
              <div className="w-full" key={row.name}>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between gap-x-5 w-full px-3 py-5 border-b border-gray-300/20"
                >
                  <span className="flex flex-grow flex-col gap-2">
                    <Switch.Label
                      as="span"
                      className="text-[22px] font-medium leading-6 text-white"
                      passive
                    >
                      {row.title}
                    </Switch.Label>
                    <Switch.Description
                      as="span"
                      className="text-[15px] text-gray-400"
                    >
                      {row.desc}
                    </Switch.Description>
                  </span>

                  <Switch
                    checked={enabled[row.name]}
                    name={row.name}
                    value={enabled[row.name]}
                    onChange={() => handleSwitchChange(row.name)}
                    className={`relative inline-flex items-center h-6 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none  focus:ring-0 ${
                      enabled[row.name] ? "bg-[#8F8FFF]" : "bg-gray-300"
                    }`}
                  >
                    <span
                      aria-hidden="true"
                      className={`pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-[#25252E] shadow ring-0 transition duration-200 ease-in-out ${
                        enabled[row.name]
                          ? "translate-x-[17px]"
                          : "translate-x-[2px]"
                      }`}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="w-full flex items-center justify-center">
        <button
          type="button"
          className={`inline-flex sm:w-1/5 justify-center rounded-lg px-4 py-3 text-sm bg-indigo-600 font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
            disabled ? "cursor-not-allowed opacity-60" : "hover:bg-indigo-500"
          }`}
          disabled={disabled}
          onClick={handleSubmit}
          ref={saveButtonRef}
        >
          {mutation.isLoading ? (
            <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
          ) : (
            "Save"
          )}
        </button>
      </div>

      <SuccessModal
        show={showSuccessMsg}
        setShow={setShowSuccessMsg}
        message={mutation?.data?.message}
      />
    </div>
  );
}
