import instance from "../../utils/axios";

const loginMethod = async ({ email, password }) => {
  try {
    const res = await instance.post("login", {
      email,
      password,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default loginMethod;
