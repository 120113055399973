import instance from "../../utils/axios";

const exportFile = async ({ format, type, route }) => {
  try {
    const res = await instance.get(`${route}?format=${format}&type=${type}`);
    const data = res.data;
    return {
      data,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export default exportFile;
