export default function Integrations() {
  return (
    <div className="p-5 w-full mx-auto flex flex-wrap gap-5 ">
      <div className="w-full lg:w-[30%] p-5 flex flex-col bg-gray-500/10 border border-gray-300/10 justify-start items-start gap-3 rounded-lg">
        <div className="w-10 h-10 p-2 flex items-center justify-center border border-gray-300/10 rounded-lg">
          <img className="w-full h-full" src="/slack.png" alt="slack" />
        </div>
        <h1 className="capitalize text-lg font-semibold m-0">slack</h1>
        <p className="text-gray-400">
          Connect a slack workspace in order to setup automated notifications
        </p>
        <button className="w-full bg-transparent border border-gray-300/10 py-2.5 rounded-lg text-lg font-semibold capitalize hover:bg-gray-400/10 duration-150">
          configure
        </button>
      </div>
    </div>
  );
}
