import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import loginMethod from "../apis/mutation/login";

const AuthContext = React.createContext();
const AuthProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tableHeader, setTableHeader] = useState(null);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userFromLocalStorage = localStorage.getItem("access_token");
    const userRole = JSON.parse(localStorage.getItem("user"));
    const from = location.state?.from?.pathname || "/";
    if (
      (userFromLocalStorage && !loggedIn && location.pathname === "/login") ||
      (userFromLocalStorage &&
        !loggedIn &&
        location.pathname === "/2-factor-authentication") ||
      (userFromLocalStorage &&
        !loggedIn &&
        location.pathname === "/forgot-password") ||
      (userFromLocalStorage &&
        !loggedIn &&
        location.pathname === "/accept-invitation") ||
      (userFromLocalStorage &&
        !loggedIn &&
        location.pathname === "/2-factor-authentication-recovery") ||
      (userFromLocalStorage &&
        !loggedIn &&
        location.pathname === "/reset-password") ||
      (loggedIn &&
        !user &&
        userRole?.role !== "customer_admin" &&
        location.pathname.startsWith("/settings"))
    ) {
      navigate(from);
    } else {
      localStorage.removeItem("secret_key");
      localStorage.removeItem("recovery_codes");
      localStorage.removeItem("qr_code");
      if (
        location.pathname !== "/2-factor-authentication" &&
        location.pathname !== "/2-factor-authentication-recovery"
      ) {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      if (!loggedIn && localStorage.getItem("isValid") === null) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
      }
    }
  }, [location.pathname, navigate]);

  const mutation = useMutation({
    mutationFn: () => {
      return loginMethod({
        email,
        password,
      });
    },
    onSuccess: (data) => {
      if (data["2fa_enabled"] === 1) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        localStorage.setItem("from", location.state?.from?.pathname || "/");
        navigate("/2-factor-authentication");
      } else {
        localStorage.setItem("secret_key", data.secret_key);
        localStorage.setItem("recovery_codes", data.recovery_codes);
        localStorage.setItem("qr_code", data.qr_code);
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("access_token", data.access_token);

        if (data["2fa_status"] === 1) {
          setLoggedIn(true);
        }

        setShowSecondModal(true);

        if (showSecondModal === false) {
          setLoggedIn(true);
        }
      }
    },
  });
  return (
    <AuthContext.Provider
      value={{
        tableHeader,
        setTableHeader,
        sidebarOpen,
        setSidebarOpen,
        password,
        setPassword,
        email,
        setEmail,
        user,
        setUser,
        mutation,
        showSecondModal,
        setShowSecondModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
