import instance from "../../utils/axios";

const resetPassword = async ({ newToken, newPassword, confirmPassword }) => {
  try {
    const res = await instance.post("reset-password", {
      token: newToken,
      password: newPassword,
      password_confirmation: confirmPassword,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default resetPassword;
