import instance from "../../utils/axios";

const getEmployeesProfiles = async ({ page = 1, perPage = "" }) => {
  try {
    const res = await instance.get(
      `organization/employees-profile?page=${page}&perPage=${perPage}`
    );
    const data = res.data.data.employees;

    return {
      data,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getEmployeesProfiles;
