import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  HiOutlineServer,
  HiOutlineGlobeAlt,
  HiXMark,
  HiOutlineUser,
  HiOutlineShieldExclamation,
  HiOutlineBugAnt,
  HiOutlineLockOpen,
  HiOutlineCog6Tooth,
  HiChevronDown,
  HiArrowPath,
} from "react-icons/hi2";
import { LuLogOut } from "react-icons/lu";
import { BiSolidDashboard } from "react-icons/bi";
import { LiaUserSecretSolid } from "react-icons/lia";
import { BsDatabaseExclamation } from "react-icons/bs";
import { FaUsers } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { useAuth } from "../../context/authProvider";

export default function SideBar() {
  const location = useLocation();
  const [profileSettings, setProfileSettings] = useState(false);
  const [isActive, setIsActive] = useState(location.pathname.split("/")[1]);
  const { sidebarOpen, setSidebarOpen } = useAuth();

  const navigate = useNavigate();
  const userRole = JSON.parse(localStorage.getItem("user"));

  const isUserAdmin = userRole?.role === "customer_admin";

  const navigation = [
    { name: "Dashboard", href: "/dashboard", icon: BiSolidDashboard },
    {
      name: "Compromised Users",
      href: "/compromised-users",
      icon: LiaUserSecretSolid,
    },
    {
      name: "Employees",
      href: "/employees",
      icon: FaUsers,
    },
    {
      name: "Data Breaches",
      href: "/data-breaches",
      icon: BsDatabaseExclamation,
    },
    { name: "Assets", href: "/assets", icon: HiOutlineGlobeAlt },
    { name: "Integrations", href: "/integrations", icon: HiArrowPath },
    ...(isUserAdmin
      ? [{ name: "Settings", href: "/settings", icon: HiOutlineCog6Tooth }]
      : []),
    { name: "Profile", href: "/profile", icon: HiOutlineUser },
  ];

  const profileSettingsRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        (profileSettingsRef.current &&
          !profileSettingsRef.current.contains(event.target)) ||
        event.key === "Escape"
      ) {
        setProfileSettings(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleClickOutside);
    };
  }, [profileSettingsRef]);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 xl:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#17191cc9]" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <HiXMark
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#17191c] px-6 ring-1 ring-white/10">
                  <div className="flex h-12 w-12 -ml-2 mt-5 mb-2 items-center">
                    <img
                      className="h-full w-full"
                      src="../DeepStrike.webp"
                      alt="Deepstrike"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                onClick={() => {
                                  setIsActive(item.href.split("/")[1]);
                                  setSidebarOpen(false);
                                }}
                                className={`group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ${
                                  isActive == item.href.split("/")[1]
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:text-white hover:bg-gray-800"
                                }`}
                              >
                                <item.icon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li className="-mx-6 mt-auto relative flex justify-center select-none">
                        <div
                          onClick={() => setProfileSettings(!profileSettings)}
                          className="w-full cursor-pointer flex justify-between items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                        >
                          <div className="flex items-center gap-x-4">
                            <div className="w-10 h-10 rounded-circle bg-[#48494B] flex justify-center items-center">
                              <span className="capitalize">
                                {userData?.first_name[0]}
                              </span>
                            </div>
                            <span className="sr-only">Your profile</span>
                            <span
                              aria-hidden="true"
                              className="flex gap-4 capitalize"
                            >
                              {userData?.first_name} {userData?.second_name}
                            </span>
                          </div>
                          <HiChevronDown
                            className={`duration-150 ease-in-out ${
                              profileSettings && "rotate-180"
                            }`}
                          />
                        </div>

                        <div
                          ref={profileSettingsRef}
                          className={`absolute w-[90%] h-0 overflow-hidden bg-gray-800  border border-gray-500 rounded-lg p-3 -top-32  flex-col justify-start items-center gap-1 ${
                            profileSettings ? "flex h-auto" : "hidden"
                          }`}
                        >
                          <Link
                            onClick={() => {
                              setSidebarOpen(false);
                              setProfileSettings(!profileSettings);
                            }}
                            to="/profile"
                            className="w-full p-2.5 flex justify-start items-center gap-3 text-base bg-transparent font-semibold text-gray-400 hover:text-white hover:bg-gray-700 rounded-lg duration-150"
                          >
                            <FiUser />
                            Settings
                          </Link>
                          <button
                            onClick={() => {
                              setProfileSettings(false);
                              localStorage.clear();

                              window.location.reload();
                            }}
                            className="w-full p-2.5 flex justify-start items-center gap-3 text-base bg-transparent font-semibold text-[#FF817C]  hover:bg-gray-700 rounded-lg duration-150"
                          >
                            <LuLogOut />
                            Logout
                          </button>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col ">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#17191CEB] px-6 ring-1 ring-white/5">
          <div className="flex justify-center h-16 w-full items-center">
            <img
              className="h-full w-auto"
              src="../DeepStrike.webp"
              alt="Deepstrike"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        onClick={() => setIsActive(item.href.split("/")[1])}
                        className={`group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold
                          ${
                            isActive == item.href.split("/")[1]
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800"
                          }
                          `}
                      >
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="-mx-6 mt-auto relative flex justify-center select-none">
                <div
                  onClick={() => setProfileSettings(!profileSettings)}
                  className="w-full cursor-pointer flex justify-between items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                >
                  <div className="flex items-center gap-x-4">
                    <div className="w-10 h-10 rounded-circle bg-[#48494B] flex justify-center items-center">
                      <span className="capitalize">
                        {userData?.first_name[0]}
                      </span>{" "}
                    </div>
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true" className="flex gap-4 capitalize">
                      {userData?.first_name} {userData?.second_name}
                    </span>
                  </div>
                  <HiChevronDown
                    className={`duration-150 ease-in-out ${
                      profileSettings && "rotate-180"
                    }`}
                  />
                </div>

                <div
                  ref={profileSettingsRef}
                  className={`absolute w-[65%] h-0 overflow-hidden bg-gray-800  border border-gray-500 rounded-lg p-3 -top-32  flex-col justify-start items-center gap-1 ${
                    profileSettings ? "flex h-auto" : "hidden"
                  }`}
                >
                  <Link
                    onClick={() => setProfileSettings(!profileSettings)}
                    to="/profile"
                    className="w-full p-2.5 flex justify-start items-center gap-3 text-sm bg-transparent font-semibold text-gray-400 hover:text-white hover:bg-gray-700 rounded-lg duration-150"
                  >
                    <FiUser />
                    Settings
                  </Link>
                  <button
                    onClick={() => {
                      setProfileSettings(false);
                      localStorage.removeItem("user");
                      localStorage.removeItem("access_token");
                      localStorage.removeItem("isValid");

                      window.location.reload();
                    }}
                    className="w-full p-2.5 flex justify-start items-center gap-3 text-sm bg-transparent font-semibold text-[#FF817C] hover:bg-gray-700 rounded-lg duration-150"
                  >
                    <LuLogOut />
                    Sign out
                  </button>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
