import instance from "../../utils/axios";

const modal = async ({ route, modalData }) => {
  try {
    const res = await instance.post(route, modalData);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default modal;
