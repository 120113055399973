import React from "react";
import { AiOutlineFileImage } from "react-icons/ai";
export default function CardLoading() {
  return (
    <div className="w-full lg:w-[70%] flex flex-col lg:flex-row gap-5 animate-pulse">
      <div className="w-full flex flex-col bg-gray-500/10 justify-center items-start p-5 gap-2">
        <div className="h-7 w-full flex justify-start items-start gap-3">
          <div className="w-1/5 h-full bg-gray-700 rounded-sm flex justify-center items-center">
            <AiOutlineFileImage className="w-full opacity-60 text-lg" />
          </div>
          <div className="w-4/5 h-full bg-gray-700 rounded-sm" />
        </div>
        <div className="h-7 w-full flex justify-start items-start gap-3">
          <div className="w-1/5 h-full bg-transparent rounded-sm " />
          <div className="w-2/5 h-full bg-gray-700 rounded-sm" />
        </div>
      </div>

      <div className="w-full flex flex-col bg-gray-500/10 justify-center items-start p-5 gap-2">
        <div className="h-7 w-full flex justify-start items-start gap-3">
          <div className="w-1/5 h-full bg-gray-700 rounded-sm flex justify-center items-center">
            <AiOutlineFileImage className="w-full opacity-60 text-lg" />
          </div>
          <div className="w-4/5 h-full bg-gray-700 rounded-sm" />
        </div>
        <div className="h-7 w-full flex justify-start items-start gap-3">
          <div className="w-1/5 h-full bg-transparent rounded-sm " />
          <div className="w-2/5 h-full bg-gray-700 rounded-sm" />
        </div>
      </div>

      <div className="w-full flex flex-col bg-gray-500/10 justify-center items-start p-5 gap-2">
        <div className="h-7 w-full flex justify-start items-start gap-3">
          <div className="w-1/5 h-full bg-gray-700 rounded-sm flex justify-center items-center">
            <AiOutlineFileImage className="w-full opacity-60 text-lg" />
          </div>
          <div className="w-4/5 h-full bg-gray-700 rounded-sm" />
        </div>
        <div className="h-7 w-full flex justify-start items-start gap-3">
          <div className="w-1/5 h-full bg-transparent rounded-sm " />
          <div className="w-2/5 h-full bg-gray-700 rounded-sm" />
        </div>
      </div>
    </div>
  );
}
