import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi2";

export default function CustomSelect({
  errors,
  setErrors,
  options,
  name,
  handleFieldChange,
  selected,
}) {
  const [active, setActive] = useState("Select");
  useEffect(() => {
    handleFieldChange(name, 3);
    selected && setActive(selected);
  }, [selected]);
  return (
    <Menu as="div" className="relative inline-block text-left w-full mt-1.5">
      <div
        onBlur={() => {
          active === "Select"
            ? setErrors({
                ...errors,
                [name]: "Please chose a role",
              })
            : setErrors({ ...errors, [name]: "" });
        }}
      >
        <Menu.Button
          className={`inline-flex w-full justify-between gap-x-1.5 text-white rounded-md bg-gray-500/10 px-3 py-3 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-400/10 hover:bg-gray-400/10 ${
            active === "Select" ? "text-gray-400" : ""
          } ${errors.role && "text-red-500 ring-red-500 animate-wiggle "}`}
        >
          {active}
          <HiChevronDown
            className={`-mr-1 h-5 w-5 ${
              errors.role ? "text-red-500" : "text-gray-300"
            }`}
            aria-hidden="true"
          />
        </Menu.Button>
        <p className="text-red-500 text-sm animate-wiggle">{errors.role}</p>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 cursor-pointer w-full origin-top-right rounded-md bg-[#23262b] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option, i) => (
              <Menu.Item
                key={i}
                onClick={() => {
                  setActive(option.name);
                  handleFieldChange(name, option.value);
                  setErrors({ ...errors, [name]: "" });
                }}
              >
                <h1
                  className={`block px-4 py-2 text-sm ${
                    active === option.name
                      ? "bg-indigo-700 text-white"
                      : "text-gray-400"
                  }`}
                >
                  {option.name}
                </h1>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
