import instance from "../../utils/axios";

const getOrganization = async (organizationRoute) => {
  try {
    const res = await instance.get(organizationRoute);
    const data = res.data.data.organization;

    return {
      data,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getOrganization;
