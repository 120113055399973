import instance from "../../utils/axios";

const deleteModal = async ({ route, modalData }) => {
  try {
    const res = await instance.delete(route, { data: modalData });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default deleteModal;
