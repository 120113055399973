import React, { useState } from "react";
import Card from "../../components/PageCards";
import Table from "../../components/Table";
import getAvailableDomains from "../../apis/query/availableDomains";
import { useQuery } from "react-query";
import Loading from "../../components/Loading";
import SuccessModal from "../../components/SuccessModal";

export default function Deployment() {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["dashBoadrd-availableDomains"],
    queryFn: () => getAvailableDomains({ domainType: 1 }),
  });
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="p-5 w-full flex flex-col gap-11 lg:w-[125%] 2xl:w-full 2xl:transform-none transform lg:scale-[0.80] 2xl:scale-100 origin-top-left pl-5 2xl:mt-0">
          <div>
            <h1 className="text-2xl text-white font-bold my-5 ">Dashboard</h1>
            <Card
              cardRoute="dashboard-stats"
              icons={[
                "emergency-siren.svg",
                "computer-bug.svg",
                "computer-bug.svg",
                "data-phishing.svg",
              ]}
              titles={[
                { name: "Open credentials", value: "open_credentials" },
                {
                  name: "compromised credentials",
                  value: "compromised_credentials_employees",
                },
                { name: "Compromised users", value: "compromised_users" },
                { name: "Compromised devices", value: "compromised_devices" },
              ]}
              dataRoute="dashboard"
              hiddenKeys={["compromised_credentials_employees"]}
            />
          </div>

          <div className="w-full bg-gray-500/10 border border-gray-400/10 rounded-lg mb-5 ">
            <Table
              queryKey="dashBoadrd"
              route="employees-credentials"
              resKey="leakedCredentials"
              pagination={true}
              select={true}
              resizableCols={true}
              tableHeader={true}
              rowAction={false}
              filterDomains={true}
              actionFilter={true}
              exportFilter={true}
              endWithFilter={true}
              manageColsFilter={true}
              tableTitle={true}
              tableDesc="You can search by employee email address, URL, username, or simply by using the compromised device ID"
              Status_Filter={true}
              exportType={1}
              exportRoute="credentials-export"
              showSuccessMsg={showSuccessMsg}
              setShowSuccessMsg={setShowSuccessMsg}
              successMsg={successMsg}
              setSuccessMsg={setSuccessMsg}
              copyCell={[
                "domain_name",
                "url",
                "email",
                "password",
                "device_id",
              ]}
              filterBTN={{
                name: "Date",
                options: [
                  { value: null },
                  { value: "2023" },
                  { value: "2022" },
                  { value: "2021" },
                ],
              }}
              status={["Fixed", "Open"]}
              victimsCountry={false}
              companyBTN="Domains"
              searchBTN={{ options: ["email", "url"] }}
              columns={[
                "domain_name",
                "url",
                "email",
                "password",
                "device_id",
                "created_at",
                "status",
              ]}
              tHead={[
                "Domain",
                "URL",
                "Username",
                "Password",
                "DeviceID",
                "Date",
                "Status",
              ]}
              companies={data}
            />
          </div>
        </div>
      )}
      {showSuccessMsg && (
        <SuccessModal
          show={showSuccessMsg}
          setShow={setShowSuccessMsg}
          message={successMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}
    </>
  );
}
