import React, { useEffect, useRef } from "react";
import { GoStop } from "react-icons/go";
import { Link } from "react-router-dom";

export default function ExpiredResetToken() {
  const resetRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && resetRef.current) {
        resetRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <>
      <div
        className="w-screen h-screen flex flex-col justify-center items-center gap-4 relative"
        style={{
          backgroundImage: 'url("../loginBG.png")',
          backgroundSize: "cover",
          backgroundPosition: "120px 120px",
        }}
      >
        <div className="w-[300px] h-[320px] absolute  bg-[#3a2b47] blur-3xl z-[1]" />

        <form className="bg-[#1B1421] opacity-90  shadow-md shadow-[#0000001a] py-[30px] px-[50px] rounded-[20px] border-[0.5px] border-[#42338E] w-full max-w-[450px] flex flex-col justify-start items-start gap-3 relative z-10">
          <div className="w-full flex justify-center items-center">
            <GoStop className="text-5xl text-red-500" />
          </div>

          <div className="w-full flex flex-col justify-start items-start gap-1">
            <h1 className="text-[#E3E5E8] text-2xl font-normal m-0">
              Expired reset token
            </h1>
            <p className="text-[#93999F] text-sm font-normal m-0 ">
             We're sorry, but the password reset link you requested has expired.
            
            </p>
          </div>

          <Link
            to="/forgot-password"
            ref={resetRef}
            className="w-full inline-flex justify-center rounded-[0.625rem] transition-all duration-300 bg-indigo-700 hover:bg-indigo-600 py-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 "
          >
            Request a new token
          </Link>
        </form>
      </div>
    </>
  );
}
