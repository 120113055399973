import React, { useState } from "react";
import Table from "../../components/Table";
import SuccessModal from "../../components/SuccessModal";
import { useQuery } from "react-query";
import getTableData from "../../apis/query/getTableData";
import EmptyInvtations from "../../components/EmptyInvtations";

export default function PendingInvtations() {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const { data, status } = useQuery({
    queryKey: ["pending-invitations"],
    queryFn: () =>
      getTableData({
        resKey: "invitations",
        route: "organization/list-invitation",
        page: 1,
        perPage: 10,
        endWith: "all",
      }),
  });
  return (
    <>
      {status === "success" && data.data.length > 0 ? (
        <div className="p-5 my-5 w-full flex flex-col overflow-hidden bg-gray-500/10 border border-gray-400/10 rounded-lg">
          <Table
            resKey="invitations"
            queryKey="list-invitation"
            route="organization/list-invitation"
            pagination={true}
            select={true}
            tableHeader={true}
            resizableCols={false}
            filterDomains={false}
            actionFilter={false}
            exportFilter={false}
            manageColsFilter={false}
            deleteFilter={true}
            tableTitle={false}
            Status_Filter={false}
            withBG={true}
            showSuccessMsg={showSuccessMsg}
            setShowSuccessMsg={setShowSuccessMsg}
            successMsg={successMsg}
            setSuccessMsg={setSuccessMsg}
            rowAction={["resend", "delete"]}
            status={["InActive", "Pending"]}
            role={["Admin", "Member"]}
            columns={["full_name", "email", "role", "invitation_status"]}
            tHead={["Full name", "Email", "Role", "Status"]}
            fields={[
              {
                label: "Name",
                name: "Name",
                type: "input",
              },
              {
                label: "Email Address",
                name: "Email Address",
                type: "input",
              },
              { options: ["Admin", "Member"], type: "select", label: "Role" },
              {
                options: ["Active", "InActive"],
                type: "select",
                label: "MFA",
              },
            ]}
            modalAdjustment={[{ header: "Update member", primaryBTN: "Save" }]}
            searchBTN={{ options: ["email", "url"] }}
            confirmationModalRoute={"organization/delete-invitation"}
            resendModalRoute={"organization/resend-invitation"}
            deleteModalMsg={"Are you sure you want to delete this invite?"}
          />
        </div>
      ) : (
        <div className="h-[70vh]">
          <EmptyInvtations />
        </div>
      )}

      {showSuccessMsg && (
        <SuccessModal
          show={showSuccessMsg}
          setShow={setShowSuccessMsg}
          message={successMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}
    </>
  );
}
