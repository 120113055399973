import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PiCircleNotchBold, PiEye, PiEyeClosed, PiXBold } from "react-icons/pi";
import CustomSelect from "../CustomSelect";
import { useMutation, useQueryClient } from "react-query";
import modal from "../../apis/mutation/modal";
import Copy from "../Copy";
import { useNavigate } from "react-router-dom";

export default function Modal({
  showModal,
  setShowModal,
  rowData,
  fields,
  modalAdjustment,
  route,
  showSecondModal,
  setShowSecondModal,
  setShowSuccessMsg,
  setSuccessMsg,
}) {
  const [viewPassword, setViewPassword] = useState(false);
  const [modalData, setModalData] = useState({});
  const [errors, setErrors] = useState({});
  const [valiedEmail, setValiedEmail] = useState(false);
  const [isValied, setIsValied] = useState(false);
  const confirmButtonRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    rowData && setModalData(rowData);
    showSecondModal && setValiedEmail(true);
  }, [rowData, showSecondModal]);

  const handleValidation = () => {
    if (modalData === rowData) {
      setIsValied(false);
    } else {
      setIsValied(true);
    }
  };

  const handleFieldChange = (fieldName, value) => {
    // setErrors({});
    mutation.reset();
    handleValidation();

    const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (fieldName === "email" && value !== "") {
      if (!emailPattern.test(value)) {
        setValiedEmail(false);
      } else {
        setValiedEmail(true);
      }
    }

    if (fieldName === "code") {
      const sanitizedValue = value.replace(/[^0-9-]/g, "");
      if (sanitizedValue.length >= 6) {
        const truncatedValue = sanitizedValue.slice(0, 6);
        setModalData((prevData) => ({
          ...prevData,
          [fieldName]: truncatedValue,
        }));
      } else {
        setModalData((prevData) => ({
          ...prevData,
          [fieldName]: sanitizedValue,
        }));
      }
    } else {
      setModalData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => {
      return modal({ route, modalData });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("Modal");
      if (showSecondModal === true && localStorage.getItem("secret_key")) {
        localStorage.removeItem("secret_key");
        localStorage.removeItem("qr_code");
        localStorage.removeItem("recovery_codes");
        localStorage.removeItem("email");
        localStorage.removeItem("password");

        navigate("/dashboard");
      }
      if (typeof setShowSecondModal === "function") {
        localStorage.setItem("secret_key", data.data[0].secret_key);
        localStorage.setItem("qr_code", data.data[0].qr_code);
        localStorage.setItem("recovery_codes", data.data[0].recovery_codes);
        setShowSecondModal(true);
      }
      setSuccessMsg(data?.message);
      setShowSuccessMsg(true);
      setShowModal(false);
      setModalData({});
      queryClient.refetchQueries();
    },
    onError: (error) => {
      if (error.response?.data?.errors?.code) {
        setErrors({
          ...errors,
          code: error.response.data.errors.code.map((message) => message),
        });
      } else {
        setErrors({
          ...errors,
          email: "The email has already been taken.",
        });
      }
    },
  });

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && confirmButtonRef.current) {
        confirmButtonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [showModal]);

  const renderField = (field, i) => {
    switch (field.type) {
      case "input":
        return (
          <div className="w-full" key={i}>
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              {field.label}
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                name={field.name}
                id={field?.id}
                className="block w-full rounded-md border-0 py-3 bg-gray-500/10 text-white shadow-sm ring-1 ring-inset ring-gray-400/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder={field.label}
                value={modalData[field.name] || ""}
                onChange={(e) => handleFieldChange(field.name, e.target.value)}
              />
            </div>
          </div>
        );

      case "desableInput":
        return (
          <div className="w-full" key={i}>
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400 "
            >
              {field.label}
            </label>
            <div className="mt-1.5 relative flex justify-between items-center gap-3">
              <input
                type="text"
                name={field.name}
                id={field?.id}
                disabled
                className="block w-full uppercase rounded-md border-0 py-3 bg-gray-500/10 cursor-not-allowed text-gray-400 shadow-sm ring-1 ring-inset ring-gray-400/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder={field.label}
                value={localStorage.getItem(field.value)}
              />
              <div className="absolute right-5">
                <Copy data={localStorage.getItem(field.value)} />
              </div>
            </div>
          </div>
        );

      case "codes":
        const dataString = localStorage.getItem(field.value);
        const dataArray = dataString ? dataString.split(",") : [];

        return (
          <div className="w-full" key={i}>
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              {field.label}
            </label>
            <div className="w-full relative flex flex-col justify-start items-start rounded-md border-0 p-3 bg-gray-500/10 ring-1 ring-inset ring-gray-400/10 uppercase cursor-not-allowed ">
              {dataArray.slice(0, 5).map((item, index) => (
                <h1
                  className="text-gray-400 shadow-sm sm:text-sm sm:leading-6"
                  key={index}
                >
                  {item}
                </h1>
              ))}
              <div className="absolute right-5">
                <Copy data={dataArray.slice(0, 5)} />
              </div>
            </div>
          </div>
        );

      case "fullNameInput":
        return (
          <div className="w-full" key={i}>
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              {field.label}
            </label>
            <div className="mt-1.5">
              <input
                ref={inputRef}
                type="text"
                name={field.name}
                id={field?.id}
                className={`block w-full rounded-md border-0 py-3 bg-gray-500/10  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${
                  errors.full_name
                    ? "text-red-500 ring-red-500 animate-wiggle focus:ring-red-600"
                    : "text-white ring-gray-400/10 focus:ring-indigo-600"
                }`}
                placeholder={field.label}
                value={modalData[field.name] || ""}
                onChange={(e) => handleFieldChange(field.name, e.target.value)}
                onBlur={(e) => {
                  if (field.name === "full_name") {
                    const sanitizedValue = e.target.value.trim();
                    const nameParts = sanitizedValue.split(" ");
                    if (
                      nameParts.length < 2 ||
                      !nameParts.every((part) => /^[A-Za-z0-9.]+$/.test(part))
                    ) {
                      setErrors({
                        ...errors,
                        [field.name]: "Please enter a valid full name",
                      });
                    } else {
                      setErrors({ ...errors, [field.name]: "" });
                    }
                  }
                }}
              />
              <p className="text-red-500 text-sm animate-wiggle">
                {errors.full_name}
              </p>
            </div>
          </div>
        );

      case "email":
        return (
          <div className="w-full" key={i}>
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              {field.label}
            </label>
            <div className="mt-1.5">
              <input
                type="email"
                name={field.name}
                id={field?.id}
                className={`block w-full rounded-md border-0 py-3 bg-gray-500/10  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${
                  errors.email
                    ? "text-red-500 ring-red-500 animate-wiggle focus:ring-red-600"
                    : "text-white ring-gray-400/10 focus:ring-indigo-600"
                }`}
                placeholder={field.label}
                value={modalData[field.name] || ""}
                onChange={(e) => handleFieldChange(field.name, e.target.value)}
                onBlur={(e) => {
                  if (field.name === "email") {
                    const emailPattern =
                      /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                    if (!emailPattern.test(e.target.value)) {
                      setErrors({
                        ...errors,
                        [field.name]: "Please enter a valid email address",
                      });
                    } else {
                      setErrors({ ...errors, [field.name]: "" });
                    }
                  }
                }}
              />
              {(mutation.isError || errors.email) && (
                <p className="text-red-500 text-sm animate-wiggle">
                  {errors.email}
                </p>
              )}
            </div>
          </div>
        );

      case "code_input":
        return (
          <div className="w-full" key={i}>
            <h1>{field?.header}</h1>
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              {field.label}
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                name={field.name}
                id={field?.id}
                className={`block w-full rounded-md border-0 py-3 bg-gray-500/10 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                  mutation.isError
                    ? "ring-red-400 text-red-400 animate-wiggle"
                    : "ring-gray-400/10 text-white"
                }`}
                placeholder={
                  field.placeholder ? field.placeholder : field.label
                }
                value={modalData[field.name] || ""}
                onChange={(e) => handleFieldChange(field.name, e.target.value)}
              />
              {mutation.isError && (
                <p className="w-full text-sm text-red-400 animate-wiggle flex flex-col">
                  {errors?.code?.map((msg) => (
                    <span>{`- ${msg}`}</span>
                  ))}
                </p>
              )}
            </div>
          </div>
        );

      case "image":
        return (
          <div className="w-full" key={i}>
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              {field.label}
            </label>
            <div className="mt-1.5 w-full flex justify-center">
              <div className="p-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="192"
                  height="192"
                  viewBox="0 0 192 192"
                  dangerouslySetInnerHTML={{
                    __html: localStorage.getItem(field.value),
                  }}
                />
              </div>
            </div>
          </div>
        );

      case "password":
        return (
          <div className="w-full" key={i}>
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              {field.label}
            </label>
            <div className="mt-1.5 flex items-center relative">
              <input
                type={viewPassword ? "text" : "password"}
                name={field.name}
                id={field?.id}
                className={`block w-full rounded-md border-0 py-3 bg-gray-500/10  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                  mutation.isError
                    ? "ring-red-400 text-red-400 animate-wiggle"
                    : "ring-gray-400/10 text-white"
                }`}
                placeholder={field.name}
                value={modalData[field.name] || ""}
                onChange={(e) => handleFieldChange(field.name, e.target.value)}
              />
              <div className="absolute right-3  duration-150">
                {viewPassword ? (
                  <PiEye
                    className={`cursor-pointer select-none ${
                      mutation.isError ? "text-red-400" : "text-white"
                    }`}
                    onClick={() => setViewPassword(!viewPassword)}
                  />
                ) : (
                  <PiEyeClosed
                    className={`cursor-pointer select-none ${
                      mutation.isError ? "text-red-400" : "text-white"
                    }`}
                    onClick={() => setViewPassword(!viewPassword)}
                  />
                )}
              </div>
            </div>
            {mutation.isError && (
              <p className="w-full text-sm text-red-400 animate-wiggle block">
                Invalid password
              </p>
            )}
          </div>
        );

      case "select":
        return (
          <div className="w-full" key={i}>
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              {field.label}
            </label>
            <CustomSelect
              errors={errors}
              setErrors={setErrors}
              options={field.options}
              name={field.name}
              handleFieldChange={handleFieldChange}
              selected={
                rowData?.role === 2
                  ? "Admin"
                  : rowData?.role === 3
                  ? "Member"
                  : "Member"
              }
            />
          </div>
        );
    }
  };

  const location = window.location.pathname;
  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999999] w-full"
        initialFocus={inputRef}
        onClose={() => {
          if (showSecondModal === true && localStorage.getItem("secret_key")) {
            localStorage.clear();
          } else {
            setShowModal(false);
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#00000066] bg-opacity-75 transition-opacity backdrop-blur" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg  px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-xl sm:p-6 ${
                  showSecondModal && location === "/login"
                    ? "bg-[#1B1421]"
                    : "bg-[#17191c]"
                }`}
              >
                <div className="w-full mb-7 flex justify-between items-center text-lg font-semibold ">
                  <h1>{modalAdjustment[0].header}</h1>
                  <button
                    onClick={() => {
                      localStorage.removeItem("secret_key");
                      localStorage.removeItem("qr_code");
                      localStorage.removeItem("recovery_codes");
                      localStorage.removeItem("email");
                      localStorage.removeItem("password");

                      setShowModal(false);
                    }}
                    className="p-1.5 bg-transparent hover:bg-slate-500/10 rounded-lg ease-in-out duration-150"
                  >
                    <PiXBold />
                  </button>
                </div>
                <div className="w-full flex flex-col gap-5 items-start justify-center">
                  {fields.map((field, i) => renderField(field, i))}
                </div>
                <div className="mt-7 w-full flex  justify-end items-center gap-2">
                  <button
                    type="button"
                    className=" inline-flex sm:w-1/4 justify-center rounded-md bg-gray-500/10 px-4 py-3 text-sm font-semibold text-gray-400 shadow-sm ring-1 ring-inset ring-gray-400/10 hover:bg-gray-300/10 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      localStorage.removeItem("secret_key");
                      localStorage.removeItem("qr_code");
                      localStorage.removeItem("recovery_codes");
                      localStorage.removeItem("email");
                      localStorage.removeItem("password");
                      setShowModal(false);
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className={`inline-flex sm:w-1/4 justify-center rounded-md bg-indigo-600 px-4 py-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 
                    ${
                      Object.values(errors).some((error) => !!error) ||
                      !Object.values(modalData).every(
                        (value) => value !== ""
                      ) ||
                      (!showSecondModal &&
                        Object.keys(modalData).length < fields.length) ||
                      (showSecondModal && !modalData.code) ||
                      (valiedEmail === false && !isValied)
                        ? "disabled cursor-not-allowed opacity-60"
                        : modalAdjustment[0].color
                        ? modalAdjustment[0].color
                        : " hover:bg-indigo-500"
                    }
                    `}
                    disabled={
                      Object.values(errors).some((error) => !!error) ||
                      !Object.values(modalData).every(
                        (value) => value !== ""
                      ) ||
                      (!showSecondModal &&
                        Object.keys(modalData).length < fields.length) ||
                      // valiedEmail === false
                      (valiedEmail === false && !isValied) ||
                      (showSecondModal && !modalData.code)
                    }
                    onClick={() => {
                      const emailPattern =
                        /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

                      const hasErrors = Object.values(errors).some(
                        (error) => !!error
                      );
                      if (
                        !hasErrors &&
                        (modalData.email
                          ? emailPattern.test(modalData.email)
                          : true)
                      ) {
                        mutation.mutate();
                      }
                    }}
                    ref={confirmButtonRef}
                  >
                    {mutation.isLoading ? (
                      <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
                    ) : (
                      modalAdjustment[0].primaryBTN
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
