import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { useAuth } from "../../context/authProvider";

export default function StatusFilter({
  filterStatus,
  handleChange,
  options,
  isBookmarked,
  setBookmarked,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const { setTableHeader } = useAuth();

  const menuRef9 = useRef(null);
  const filterRef9 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (!filterRef9.current || !filterRef9.current.contains(event.target)) &&
        (!menuRef9.current || !menuRef9.current.contains(event.target))
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative h-full w-full lg:w-max border border-gray-500 rounded-lg bg-gray-500/10 sm:text-sm"
      ref={filterRef9}
    >
      <div
        className="w-full lg:w-max px-3 py-2 flex justify-center lg:justify-between items-center gap-2 text-white  h-full cursor-pointer"
        onClick={() => {
          setTableHeader(4);
          setShowOptions(!showOptions);
        }}
      >
        <h1 className="leading-[1.3] font-semibold text-indigo-500 capitalize">
          <span className="text-white">Status: </span>
          {filterStatus === null
            ? "All"
            : filterStatus === "closed"
            ? "fixed"
            : filterStatus}
        </h1>
        <HiChevronDown
          className={`duration-150 ease-in-out ${showOptions && "rotate-180"}`}
        />
      </div>

      <div
        className={`absolute z-10 w-full lg:min-w-fit left-0 top-11 lg:top-14 p-2 border border-gray-500 flex-col justify-start items-start bg-gray-800 rounded-lg text-white sm:text-sm ${
          showOptions ? "flex" : "hidden"
        }`}
        ref={menuRef9}
      >
        {options.map((option, i) => {
          const isActive =
            filterStatus === null && i === 0
              ? true
              : filterStatus === option?.value;
          return (
            <button
              key={i}
              className="w-full flex items-center gap-3 p-3 border-0 bg-transparent hover:bg-slate-500/10"
              onClick={() => {
                handleChange(option.value, isBookmarked);
                setShowOptions(false);
              }}
            >
              <div
                className={`w-5 h-5 rounded-full flex items-center justify-center border-[2px] ${
                  isActive
                    ? "bg-indigo-600 border-indigo-600"
                    : "bg-gray-700 border-gray-600"
                }`}
              >
                <div
                  className={`h-[8px] w-[8px] rounded-full ${
                    isActive ? "bg-indigo-950" : "bg-transparent"
                  }`}
                ></div>
              </div>
              <h1 className="text-sm font-medium m-0 capitalize">
                {option?.value === null
                  ? "All"
                  : option?.value === "closed"
                  ? "fixed"
                  : option?.value}
              </h1>
            </button>
          );
        })}

        {/* <div className="w-full py-1">
          <div className="h-[1px] w-full bg-gray-700" />
        </div>

        <button
          className="w-full flex items-center gap-3 p-3 border-0 bg-transparent hover:bg-slate-500/10"
          onClick={() => {
            setBookmarked(!isBookmarked);
          }}
        >
          <input
            id="comments"
            aria-describedby="comments-description"
            checked={isBookmarked}
            onChange={() => {
              setBookmarked(!isBookmarked);
            }}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
          <h1 className="text-sm font-medium m-0 capitalize">Bookmarked</h1>
        </button> */}
      </div>
    </div>
  );
}
