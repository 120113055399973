import React from "react";

export default function EmptyEmployees() {
  return (
    <div className="w-full h-full my-auto flex flex-col justify-center items-center gap-2 overflow-hidden">
      <img
        src="../man-showing-company-id-data.svg"
        alt="unread_message"
        className="w-96 h-96 opacity-50"
      />
      <h1 className="text-2xl font-bold">No employees profiles to view</h1>
      <p className="text-gray-400 text-center">Please check later</p>
    </div>
  );
}
