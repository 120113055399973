import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import forgotPassword from "../../apis/mutation/forgotPassword";
import { PiCircleNotchBold } from "react-icons/pi";
import { BiArrowBack } from "react-icons/bi";
import EmailSent from "../../components/EmailSent";

export default function ForgotPassword() {
  const [emailError, setEmailError] = useState("");
  const [showAlerts, setShowAlerts] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [sentSuccess, setSentSuccess] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      setShowAlerts(true);
      // setSuccess(false);
    } else {
      setEmailError("");
    }
  };
  const validateForm = () => {
    const isEmailValid = validateEmail(email);

    if (!isEmailValid) {
      setShowAlerts(true);
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }

    return isEmailValid;
  };

  const mutation = useMutation({
    mutationFn: () => {
      return forgotPassword({ email });
    },
    onSuccess: () => {
      setSentSuccess(true);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowAlerts(true);
      // setSuccess(true);
      mutation.mutate();
    }
  };

  return (
    <>
      {sentSuccess ? (
        <EmailSent setSentSuccess={setSentSuccess} />
      ) : (
        <div
          className="w-screen h-screen bg-[#1c1f22] flex flex-col justify-center items-center gap-4 relative"
          style={{
            backgroundImage: 'url("../loginBG.png")',
            backgroundSize: "cover",
            backgroundPosition: "120px 120px",
          }}
        >
          <div className="w-[300px] h-[320px] absolute  bg-[#3a2b47] blur-3xl z-[1]" />

          <form
            onSubmit={handleSubmit}
            className="bg-[#1B1421] opacity-90  shadow-md shadow-[#0000001a] py-[30px] px-[50px] rounded-[20px] border-[0.5px] border-[#42338E] w-full max-w-[450px] flex flex-col justify-center items-center gap-3 relative z-10"
          >
            {/* logo */}
            <div className="w-full flex justify-center items-center">
              <Link
                className="w-16 h-16 "
                to="https://deepstrike.io/"
                target="_blank"
              >
                <img
                  src="DeepStrike.webp"
                  alt="DeepStrike"
                  className="w-full h-full"
                />
              </Link>
            </div>

            <div className="w-full flex flex-col justify-start items-start gap-1">
              <h1 className="text-[#E3E5E8] text-2xl font-normal m-0">
                Forgot Password?
              </h1>
              <p className="text-[#93999F] text-sm font-normal m-0 ">
                You’ll receive an email with a link to reset your account
                password
              </p>
            </div>

            <div className="w-full flex flex-col items-center justify-start gap-1 mb-2">
              {showAlerts &&
                (emailError || success) &&
                mutation.isLoading !== true && (
                  <div
                    className={`w-full p-2.5 mb-3 bg-[#1c1f22] text-[#e3e5e8] border rounded-xl text-base flex flex-col items-start gap-2 ${
                      emailError
                        ? "border-[#EB6461] text-[#e97270]"
                        : // : success
                          // ? "border-green-400 bg-green-200/10"
                          "border-[#484d51]"
                    }`}
                  >
                    {emailError && <p>{emailError}</p>}
                    {/* {success && !emailError && (
                      <p>
                        Check your email for a link to reset your password. If
                        it doesn't appear within a few minutes, check your spam
                        folder.
                      </p>
                    )} */}
                  </div>
                )}

              <input
                className={`w-full bg-[#1B1421]  text-[#e3e5e8] border border-[#42338E] outline-0 px-3 py-2 rounded-[0.625rem] focus:ring-0 ${
                  emailError ? "border-[#EB6461]" : ""
                }`}
                placeholder="Enter your email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleEmailChange}
              />
            </div>

            <button
              type="submit"
              className={`w-full inline-flex justify-center rounded-[0.625rem] transition-all duration-300 bg-indigo-700 py-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                emailError || email === ""
                  ? "cursor-not-allowed opacity-50"
                  : "hover:bg-indigo-600"
              }`}
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? (
                <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
              ) : (
                "Reset Password"
              )}
            </button>

            <div className="w-full flex justify-center items-center relative">
              <span className="text-white text-base bg-[#1B1421] py-1 px-2">
                OR
              </span>
              <div className="absolute w-full h-[0.2px] bg-[#93999f] z-[-1]" />
            </div>
            <Link
              className="text-[#6650F1] text-base text-center  group transition duration-300"
              to="/login"
            >
              <div className="flex justify-center items-center gap-2">
                <BiArrowBack />
                Go back to sign in
              </div>
              <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-[#6650F1] "></span>
            </Link>
          </form>
        </div>
      )}
    </>
  );
}
