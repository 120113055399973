import instance from "../../utils/axios";

const getAvailableDomains = async ({ domainType = null } = {}) => {
  try {
    const res = await instance.get("available-domains");
    const domains = res.data.data.domains;
    const filteredDomains = domainType
      ? domains.filter((domain) => domain.type === domainType)
      : domains;

    return filteredDomains;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getAvailableDomains;
