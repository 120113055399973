import { Outlet, useLocation } from "react-router-dom";
import SideBar from "../SideBar";
import NavBar from "../NavBar";
import NotFound from "../../pages/NotFound";

const Layout = () => {
  const { pathname } = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));

  const adminRoutes = [
    "/settings",
    "/settings/",
    "/settings/members",
    "/settings/pending-invitations",
  ];

  const protectedRoutes = [
    "/dashboard",
    "/assets",
    "/profile",
    "/settings",
    "/settings/",
    "/settings/members",
    "/settings/pending-invitations",
    "/employees",
    "/compromised-users",
    "/data-breaches",
    "/integrations",
    "/",
  ].includes(pathname);

  return (
    <>
      {!protectedRoutes ? (
        <main>
          <Outlet />
        </main>
      ) : user !== null &&
        user.role !== "customer_admin" &&
        adminRoutes.includes(pathname) ? (
        <NotFound />
      ) : (
        <div className="flex relative w-full ">
          <SideBar />
          <main className="xl:pl-72 w-full flex flex-col">
            <NavBar />

            <Outlet />
          </main>
        </div>
      )}
    </>
  );
};

export default Layout;
