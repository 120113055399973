import React from "react";

import { useQuery } from "react-query";
import getCardData from "../../apis/query/getCardData";
import CardLoading from "../CardLoading";

export default function Card({
  cardRoute,
  dataRoute,
  icons,
  titles,
  hiddenKeys,
}) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["cardData"],
    queryFn: () => getCardData(cardRoute),
  });

  if (
    isError ||
    isLoading ||
    !data.data.data[dataRoute] ||
    data.data.data[dataRoute].length === 0
  ) {
    return <CardLoading />;
  }
  if (data) {
    return (
      <div className="w-full flex flex-col lg:flex-row lg:justify-between gap-5">
        {Object.entries(data.data.data[dataRoute][0]).map(([key, value], i) => {
          if (hiddenKeys && hiddenKeys.includes(key)) {
            return null;
          }
          return (
            <div
              key={i}
              className={`w-full lg:w-[33%] h-[25vh] 2xl:h-[18vh] rounded-lg bg-gray-500/10 border border-gray-800 shadow-lg	shadow-[#272949] flex flex-col justify-start items-center p-5 gap-1 hover:scale-105 duration-500 relative ${
                key === "compromised_devices" && "order-last"
              }`}
            >
              <div className="w-full flex justify-center items-center gap-2">
                <div className="bg-gray-400/10 p-2 rounded-md text-[1.9rem]">
                  <img src={`../${icons[i]}`} alt="" className="w-12" />
                </div>
                <h1 className="m-0 text-2xl font-bold text-gray-500">
                  {titles.map((title) => {
                    if (title.value === key) {
                      return title.name;
                    }
                  })}
                </h1>
              </div>
              <div className="w-full px-10 text-center">
                {typeof value === "number" ? (
                  <h1 className="m-0 text-white text-2xl font-bold">{value}</h1>
                ) : (
                  <div className="w-full flex justify-around items-center">
                    <h1 className="m-0 text-white text-2xl font-bold">
                      {value?.total}
                    </h1>
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex justify-center items-center gap-3">
                        <img
                          src="../open_credentials_employee.svg"
                          alt="open_credentials_employee"
                          className="w-6 h-6"
                        />
                        <h1 className="m-0 text-white text-2xl font-bold">
                          {value?.open_credentials_employee}
                        </h1>
                      </div>
                      <div className="flex justify-center items-center gap-3">
                        <img
                          src="../open_credentials_employee.svg"
                          alt="open_credentials_employee"
                          className="w-6 h-6"
                        />
                        <h1 className="m-0 text-white text-2xl font-bold">
                          {value?.open_credentials_users}
                        </h1>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <div>No data available</div>;
  }
}
