import instance from "../../utils/axios";

const recoveryFactorAuthenticator = async ({ email, password, authCode }) => {
  try {
    const res = await instance.post("login", {
      email: email,
      password: password,
      recovery_code: authCode,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default recoveryFactorAuthenticator;
