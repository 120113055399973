import { Routes, Route } from "react-router-dom";

import Assets from "./pages/Assets";
import Profile from "./pages/Profile";

import Workspace from "./pages/Settings";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import HackedEmployee from "./pages/Employees";
import CompromisedUsers from "./pages/CompromisedUsers";
import Breaches from "./pages/Breaches/index";
import Deployment from "./pages/Dashboard/index";
import AcceptInvitation from "./pages/AcceptInvitation";
import FactorAuthenticator from "./pages/FactorAuthenticator";
import RecoveryFactorAuthenticator from "./pages/RecoveryFactorAuthenticator";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import NotFound from "./pages/NotFound";
import Integrations from "./pages/Integrations";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public routes */}
        <Route path="/login" index element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/accept-invitation" element={<AcceptInvitation />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/2-factor-authentication"
          element={<FactorAuthenticator />}
        />
        <Route
          path="/2-factor-authentication-recovery"
          element={<RecoveryFactorAuthenticator />}
        />

        {/* protect routes */}
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Deployment />} />
          <Route path="/dashboard" element={<Deployment />} />
          <Route path="/assets" element={<Assets />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings/*" element={<Workspace />} />
          <Route path="/employees" element={<HackedEmployee />} />
          <Route path="/compromised-users" element={<CompromisedUsers />} />
          <Route path="/data-breaches" element={<Breaches />} />
          <Route path="/integrations" element={<Integrations />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
