import React, { useState } from "react";
import Table from "../../components/Table";
import { FcDataProtection } from "react-icons/fc";
import Modal from "../../components/Modal";
import SuccessModal from "../../components/SuccessModal";

export default function Assets() {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  return (
    <>
      <div className="p-5 w-full flex flex-col overflow-hidden">
        <div className="w-full p-3 sm:p-10 bg-gray-400/10 border border-gray-300/10 rounded-lg flex flex-col justify-center items-center gap-4 ">
          <div className="w-full flex flex-col justify-center items-start gap-4">
            <div className="w-full flex justify-start items-center gap-5 text-2xl font-semibold">
              {/* <FcDataProtection className="text-4xl" /> */}
              <div className="w-16 h-16 flex justify-center items-center">
                <img
                  src="../target.svg"
                  alt="assets logo"
                  className="overflow-hidden w-full"
                />
              </div>
              <h1 className="m-0">Monitoring Scope</h1>
            </div>
            <p className="text-base text-gray-300">
              On this page you can find all tracked domains by DeepStrike on the
              dark web. Want to add more?
            </p>
          </div>

          {/* <div className="w-full flex justify-end items-center">
            <button
              type="button"
              className="rounded-xl bg-indigo-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 duration-200 ease-in-out"
              onClick={() => setShowModal(true)}
            >
            + New domain 
            </button>
          </div> */}

          <div className="w-full bg-gray-500/10 border border-gray-400/10 rounded-lg">
            <Table
              resKey="domains"
              queryKey="assets"
              route={"available-domains-details"}
              pagination={false}
              select={false}
              tableHeader={false}
              resizableCols={false}
              rowAction={false}
              domainType={1}
              showSuccessMsg={showSuccessMsg}
              setShowSuccessMsg={setShowSuccessMsg}
              successMsg={successMsg}
              setSuccessMsg={setSuccessMsg}
              status={["Active", "InActive"]}
              columns={[
                "domain",
                "url_count",
                "last_scan_date",
                // "domain_type",
                "domain_status",
              ]}
              tHead={[
                "Domain",
                "Subdomain Count",
                "Last Scan",
                // "Leaks",
                "Domain Status",
              ]}
              companies={null}
            />
          </div>

          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            fields={[
              {
                label: "Assistant Name",
                name: "Assistant Name",
                type: "input",
              },
              { label: "Add Robot", name: "Add Robot", type: "input" },
            ]}
            modalAdjustment={[
              { header: "Create New Assets", primaryBTN: "Create" },
            ]}
          />
        </div>
      </div>
      {showSuccessMsg && (
        <SuccessModal
          show={showSuccessMsg}
          setShow={setShowSuccessMsg}
          message={successMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}
    </>
  );
}
