import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { useMutation, useQueryClient } from "react-query";
import updateCredentials from "../../apis/mutation/updateStatus";
import { useAuth } from "../../context/authProvider";

export default function Actions({
  show,
  setShow,
  selectedid,
  setSuccessMsg,
  queryKey,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const queryClient = useQueryClient();
  const { setTableHeader } = useAuth();
  const menuRef5 = useRef(null);
  const filterRef5 = useRef(null);

  const options = [
    { name: "Mark as fixed", route: "update-credentials" },
    { name: "Bookmark", route: "credentials-bookmark" },
  ];

  const mutation = useMutation({
    mutationFn: (route) => {
      return updateCredentials({ id: selectedid, route: route });
    },
    onSuccess: () => {
      setShowOptions(false);
      setSuccessMsg("Credentials status has been updated successfully");
      setShow(!show);
      queryClient.invalidateQueries(queryKey);
    },
  });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (!filterRef5.current || !filterRef5.current.contains(event.target)) &&
        (!menuRef5.current || !menuRef5.current.contains(event.target))
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative h-full w-full lg:w-max border border-gray-500 rounded-lg bg-gray-500/10 sm:text-sm"
      ref={filterRef5}
    >
      <div
        className="w-full lg:w-max px-3 py-2 flex justify-center lg:justify-between items-center gap-2 text-white  h-full  cursor-pointer"
        onClick={() => {
          setTableHeader(5);
          setShowOptions(!showOptions);
        }}
      >
        <h1 className="leading-[1.3] font-semibold">Actions</h1>
        <HiChevronDown
          className={`duration-150 ease-in-out ${showOptions && "rotate-180"}`}
        />
      </div>

      <div
        className={`absolute z-10 w-full lg:w-[200%] left-0 lg:-left-auto top-11 lg:top-14 p-2 border border-gray-500 flex-col justify-start items-start bg-gray-800 rounded-lg text-white sm:text-sm ${
          showOptions ? "flex" : "hidden"
        }`}
        ref={menuRef5}
      >
        {options.map((option, i) => (
          <button
            key={i}
            className="w-full flex items-center gap-3 p-3 border-0 bg-transparent hover:bg-slate-500/10"
            onClick={() => mutation.mutate(option.route)}
          >
            <h1 className="text-sm font-medium m-0">{option.name}</h1>
          </button>
        ))}
      </div>
    </div>
  );
}
