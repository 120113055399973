import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PiCircleNotchBold, PiXBold } from "react-icons/pi";
import { useMutation, useQueryClient } from "react-query";
import modal from "../../apis/mutation/modal";

export default function ResendModal({
  showModal,
  setShowModal,
  modalAdjustment,
  route,
  userId,
  setUserId,
  showSuccessMsg,
  setShowSuccessMsg,
  setSuccessMsg,
}) {
  const modalData = { id: userId };
  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      return modal({ route, modalData });
    },
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries("confirm-modal");
      setSuccessMsg("Invitation has been resent successfully");
      setShowSuccessMsg(!showSuccessMsg);
      queryClient.refetchQueries();
    },
  });

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999999] w-full"
        initialFocus={cancelButtonRef}
        onClose={setShowModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#00000066] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#17191c] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-xl sm:p-6">
                <div className="w-full mb-7 flex justify-between items-center text-lg font-semibold ">
                  <h1>{modalAdjustment[0].header}</h1>
                  <button
                    onClick={() => setShowModal(false)}
                    className="p-1.5 bg-transparent hover:bg-slate-500/10 rounded-lg ease-in-out duration-150"
                  >
                    <PiXBold />
                  </button>
                </div>
                <div className="w-full flex flex-col gap-1 items-start justify-center">
                  <h1 className="text-lg font-medium m-0">
                  Are you sure you want to resend this user invitation?
                  </h1>
                </div>
                <div className="mt-7 w-full flex  justify-end items-center gap-2">
                  <button
                    type="button"
                    className=" inline-flex sm:w-1/4 justify-center rounded-md bg-gray-500/10 px-4 py-3 text-sm font-semibold text-gray-400 shadow-sm ring-1 ring-inset ring-gray-400/10 hover:bg-gray-300/10 sm:col-start-1 sm:mt-0"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex sm:w-1/4 justify-center rounded-md bg-indigo-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={() => {
                      mutation.mutate();
                      setShowModal(false);
                    }}
                    ref={cancelButtonRef}
                    disabled={mutation.isLoading}
                  >
                    {mutation.isLoading ? (
                      <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
                    ) : (
                      modalAdjustment[0].primaryBTN
                    )}{" "}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
