import instance from "../../utils/axios";

const getOrganizationInvitation = async (getOrganizationInvitationRoute) => {
  try {
    const res = await instance.get(getOrganizationInvitationRoute);
    const data = res.data.data.invitations;

    return {
      data,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getOrganizationInvitation;
