import instance from "../../utils/axios";

const forgotPassword = async ({ email }) => {
  try {
    const res = await instance.post("forget-password", {
      email,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default forgotPassword;
