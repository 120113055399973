import React from "react";
export default function EmployeesLoading() {
  return (
    <div className="w-full p-5 flex flex-col lg:flex-row lg:justify-center flex-wrap gap-5 animate-pulse">
      <div className="w-full lg:w-[32%] flex flex-col bg-gray-500/10 justify-center items-start px-5 py-3 rounded-lg gap-2">
        <div className="h-7 w-full flex justify-between items-start gap-3">
          <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
          <div className="w-1/5 h-5 bg-gray-700 rounded-lg flex justify-center items-center" />
        </div>

        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
      </div>

      <div className="w-full lg:w-[32%] flex flex-col bg-gray-500/10 justify-center items-start px-5 py-3 rounded-lg gap-2">
        <div className="h-7 w-full flex justify-between items-start gap-3">
          <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
          <div className="w-1/5 h-5 bg-gray-700 rounded-lg flex justify-center items-center" />
        </div>

        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
      </div>

      <div className="w-full lg:w-[32%] flex flex-col bg-gray-500/10 justify-center items-start px-5 py-3 rounded-lg gap-2">
        <div className="h-7 w-full flex justify-between items-start gap-3">
          <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
          <div className="w-1/5 h-5 bg-gray-700 rounded-lg flex justify-center items-center" />
        </div>

        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
      </div>

      <div className="w-full lg:w-[32%] flex flex-col bg-gray-500/10 justify-center items-start px-5 py-3 rounded-lg gap-2">
        <div className="h-7 w-full flex justify-between items-start gap-3">
          <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
          <div className="w-1/5 h-5 bg-gray-700 rounded-lg flex justify-center items-center" />
        </div>

        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
      </div>

      <div className="w-full lg:w-[32%] flex flex-col bg-gray-500/10 justify-center items-start px-5 py-3 rounded-lg gap-2">
        <div className="h-7 w-full flex justify-between items-start gap-3">
          <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
          <div className="w-1/5 h-5 bg-gray-700 rounded-lg flex justify-center items-center" />
        </div>

        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
      </div>

      <div className="w-full lg:w-[32%] flex flex-col bg-gray-500/10 justify-center items-start px-5 py-3 rounded-lg gap-2">
        <div className="h-7 w-full flex justify-between items-start gap-3">
          <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
          <div className="w-1/5 h-5 bg-gray-700 rounded-lg flex justify-center items-center" />
        </div>

        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
        <div className="w-3/5 h-5 bg-gray-700 rounded-lg" />
      </div>
    </div>
  );
}
