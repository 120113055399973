import instance from "../../utils/axios";

const getCardData = async (cardRoute) => {
  try {
    const res = await instance.get(cardRoute);
    const data = res.data;

    return {
      data,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getCardData;
