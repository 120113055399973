import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/authProvider";
import { PiCircleNotchBold, PiEye, PiEyeClosed } from "react-icons/pi";
import Modal from "../../components/Modal";

export default function LoginScreen() {
  const {
    mutation,
    password,
    setPassword,
    email,
    setEmail,
    showSecondModal,
    setShowSecondModal,
  } = useAuth();
  const [viewPassword, setViewPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showAlerts, setShowAlerts] = useState(false);

  const loginBtnRef = useRef(null);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    if (password === "") {
      return false;
    }

    return true;
  };

  const handleEmailChange = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      setShowAlerts(true);
    } else {
      setEmailError("");
      setShowAlerts(false);
      mutation.reset();
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (!validatePassword(newPassword)) {
      setPasswordError("");
      setShowAlerts(true);
    } else {
      setPasswordError("");
    }
  };

  const validateForm = () => {
    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);
    if (!isEmailValid) {
      setShowAlerts(true);
      email === ""
        ? setEmailError("Please enter a username or email")
        : setEmailError("Invalid email address");
    } else {
      setShowAlerts(false);
    }

    if (!isPasswordValid) {
      setShowAlerts(true);
      password === ""
        ? setPasswordError("Please enter a password")
        : setPasswordError("Invalid Password");
    } else {
      setShowAlerts(false);
    }

    return isEmailValid && isPasswordValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      mutation.mutate();
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && loginBtnRef.current) {
        loginBtnRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      <div
        className="w-screen h-screen flex flex-col justify-center items-center gap-4 relative"
        style={{
          backgroundImage: 'url("../loginBG.png")',
          backgroundSize: "cover",
          backgroundPosition: "120px 120px",
        }}
      >
        <div className="w-[300px] h-[320px] absolute  bg-[#3a2b47] blur-3xl z-[1]" />

        <div className="bg-[#1B1421] opacity-90  shadow-md shadow-[#0000001a] py-[30px] px-[50px] rounded-[20px] border-[0.5px] border-[#42338E] w-full max-w-[450px] flex flex-col justify-start items-start gap-3 relative z-10">
          {/* logo */}
          <div className="w-full flex justify-center items-center">
            <Link
              className="w-16 h-16"
              to="https://deepstrike.io/"
              target="_blank"
            >
              <img
                src="DeepStrike.webp"
                alt="DeepStrike"
                className="w-full h-full"
              />
            </Link>
          </div>

          <div className="w-full flex flex-col justify-start items-start gap-1">
            <h1 className="text-[#E3E5E8] text-2xl font-normal m-0">
              Sign in to your account
            </h1>
            <p className="text-[#93999F] text-sm font-normal m-0 ">
              Welcome back to DeepStrike! 👋
            </p>
          </div>

          <div className="w-full flex flex-col items-center justify-start gap-1 mb-2">
            {mutation.isError && (
              <div className="w-full p-2.5 mb-3 bg-[#2D2023] border border-[#EB6461] rounded-xl text-sm text-[#e97270] flex flex-col items-start gap-2">
                <p>
                  {mutation?.error?.response?.data?.errors?.message ??
                    "Somthing went wrrong"}
                </p>
              </div>
            )}
            <div className="w-full">
              <input
                className={`w-full text-[#e3e5e8] border border-[#42338E] outline-0 px-3 py-2 rounded-[0.625rem] focus:border-[#735bf0]  focus:ring-0 ${
                  emailError || mutation.isError ? "border-[#EB6461]" : ""
                } ${email === "" ? "bg-[#1B1421]" : "bg-[#241a2c]"}`}
                placeholder="Enter your work email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleEmailChange}
              />
              {emailError && (
                <p className="text-sm text-[#e97270]">{emailError}</p>
              )}
            </div>
            <div className="w-full mt-2">
              <div className="w-full relative flex items-center">
                <input
                  className={`w-full text-[#e3e5e8] border border-[#42338E]  focus:border-[#735bf0] outline-0 px-3 py-2 rounded-[0.625rem] focus:ring-0 ${
                    passwordError || mutation.isError ? "border-[#EB6461]" : ""
                  } ${password === "" ? "bg-[#1B1421]" : "bg-[#241a2c]"}`}
                  placeholder="Password"
                  type={viewPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={handlePasswordChange}
                />

                <div className="absolute right-3  duration-150">
                  {viewPassword ? (
                    <PiEye
                      className="cursor-pointer select-none"
                      onClick={() => setViewPassword(0)}
                    />
                  ) : (
                    <PiEyeClosed
                      className="cursor-pointer select-none"
                      onClick={() => setViewPassword(2)}
                    />
                  )}
                </div>
              </div>
              {passwordError && (
                <p className="text-sm text-[#e97270]">{passwordError}</p>
              )}
            </div>
            <Link
              to="/forgot-password"
              className="text-[#93999f] text-sm group transition duration-300"
            >
              Forgot Password?
              <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-[#93999f] "></span>
            </Link>
          </div>

          <button
            type="button"
            className={`w-full inline-flex justify-center rounded-[0.625rem] transition-all duration-300 bg-indigo-700  py-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
              mutation.isLoading ||
              email === "" ||
              password === "" ||
              emailError ||
              passwordError
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-indigo-600"
            }`}
            disabled={
              mutation.isLoading ||
              email === "" ||
              password === "" ||
              emailError ||
              passwordError
            }
            onClick={() => {
              handleSubmit();
            }}
            ref={loginBtnRef}
          >
            {mutation.isLoading ? (
              <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
            ) : (
              "Log In"
            )}
          </button>
        </div>
      </div>
      <Modal
        showModal={showSecondModal}
        showSecondModal={showSecondModal}
        setShowModal={setShowSecondModal}
        fields={[
          {
            label: "Scan the below QR code with your authenticator app",
            name: "qr_code",
            type: "image",
            value: "qr_code",
          },
          {
            label:
              "If you cannot scan the QR code, you can manually enter the below secret key into your authenitcator application",
            name: "input",
            type: "desableInput",
            value: "secret_key",
          },
          {
            label: "Recovery codes",
            name: "input",
            type: "codes",
            value: "recovery_codes",
          },
          {
            label:
              "Once you've scanned the QR code or entered the secret key, complete the setup by entering the code listed in your authenticator app.",
            name: "code",
            type: "code_input",
            header: "Enter the code",
            placeholder: "Enter the code here",
          },
        ]}
        modalAdjustment={[
          {
            header: "Setup Multi-Factor Authentication ",
            primaryBTN: "Submit",
          },
        ]}
        route={"profile/activate-2fa"}
      />
    </>
  );
}
