import React from "react";
import { PiCircleNotchBold } from "react-icons/pi";

export default function Loading() {
  return (
    <div className="w-full h-[90vh] flex justify-center items-center gap-2 text-lg font-base">
      <PiCircleNotchBold className="animate-spin" />
      <h1>Loading...</h1>
    </div>
  );
}
