import instance from "../../utils/axios";

const acceptInvite = async ({ newToken, newPassword, confirmPassword }) => {
  try {
    const res = await instance.post("accept-invitation", {
      invitation_token: newToken,
      password: newPassword,
      password_confirmation: confirmPassword,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default acceptInvite;
