import React, { useState } from "react";
import Table from "../../components/Table";
import getAvailableDomains from "../../apis/query/availableDomains";
import { useQuery } from "react-query";
import Loading from "../../components/Loading";
import SuccessModal from "../../components/SuccessModal";

export default function CompromisedUsers() {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["compromisedUsers-availableDomains"],
    queryFn: () => getAvailableDomains({ domainType: 2 }),
  });

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="p-5 w-full flex flex-col gap-11 lg:w-[125%] 2xl:w-full 2xl:transform-none transform lg:scale-[0.80] 2xl:scale-100 origin-top-left pl-5 2xl:mt-0">
          <div className="w-full bg-gray-500/10 border border-gray-400/10 rounded-lg mb-5">
            <Table
              queryKey={"compromised-users"}
              route={"users-credentials"}
              resKey="leakedCredentials"
              pagination={true}
              select={true}
              resizableCols={true}
              tableHeader={true}
              rowAction={false}
              filterDomains={true}
              actionFilter={true}
              exportFilter={true}
              manageColsFilter={true}
              tableTitle={true}
              tableDesc="You can search by user email address, phone number, username, or by login page URL"
              Status_Filter={true}
              exportType={2}
              exportRoute="credentials-export"
              showSuccessMsg={showSuccessMsg}
              setShowSuccessMsg={setShowSuccessMsg}
              successMsg={successMsg}
              setSuccessMsg={setSuccessMsg}
              copyCell={["domain_name", "url", "email", "password"]}
              filterBTN={{
                name: "Date",
                options: [
                  { value: null },
                  { value: -30 },
                  { value: -7 },
                  { value: -1 },
                ],
              }}
              status={["Fixed", "Open"]}
              victimsCountry={true}
              companyBTN="Domains"
              searchBTN={{ options: ["email", "url"] }}
              columns={[
                "domain_name",
                "url",
                "email",
                "password",
                "country",
                "created_at",
                "status",
              ]}
              tHead={[
                "Domain",
                "URL",
                "Username",
                "Password",
                "Country",
                "Date",
                "Status",
              ]}
              companies={data}
            />
          </div>
        </div>
      )}
      {showSuccessMsg && (
        <SuccessModal
          show={showSuccessMsg}
          setShow={setShowSuccessMsg}
          message={successMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}
    </>
  );
}
