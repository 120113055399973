import instance from "../../utils/axios";

const updateUserInfo = async (
  first_name,
  last_name,
  phone_number,
  job_title
) => {
  try {
    const res = await instance.post("profile", {
      first_name: first_name,
      last_name: last_name,
      phone_number: phone_number,
      job_title: job_title,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default updateUserInfo;
