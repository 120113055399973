import React, { useEffect } from "react";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { HiOutlineCheckCircle, HiMiniXMark } from "react-icons/hi2";

export default function SuccessModal({
  show,
  setShow,
  message,
  setSuccessMsg,
}) {
  useEffect(() => {
    let timer;

    if (show) {
      timer = setTimeout(() => {
        setShow(false);
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [show, setShow]);

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed right-0 bottom-[5vh] inset-x-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-[#25272B] shadow-lg ring-1 ring-black ring-opacity-5 border border-gray-700">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <HiOutlineCheckCircle
                      className="h-6 w-6 text-green-400 font-semibold"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 ">
                    <p className="mt-0.5 text-sm text-white">{message}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-[#25272B] text-white hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <HiMiniXMark className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
