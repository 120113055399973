import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import factorAuthenticator from "../../apis/mutation/factorAuthenticator";
import { useAuth } from "../../context/authProvider";
import { PiCircleNotchBold } from "react-icons/pi";
import { LuLogOut } from "react-icons/lu";

export default function FactorAuthenticatorScreen() {
  const [authCode, setAuthCode] = useState("");
  const [error, setError] = useState(false);
  const { setUser, setEmail, setPassword } = useAuth();
  const navigate = useNavigate();

  const routes = [
    "/dashboard",
    "/assets",
    "/profile",
    "/settings/*",
    "/employees",
    "/compromised-users",
    "/data-breaches",
    "/integrations",
  ];

  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");
  const from = localStorage.getItem("from");

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      return factorAuthenticator({ email, password, authCode });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("factorAuthenticator");
      localStorage.setItem("isValid", "true");

      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("user", JSON.stringify(data));
      setUser(data);
      delete data.access_token;
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      if (routes.includes(from)) {
        navigate(from);
      } else {
        navigate("/");
        window.location.reload();
      }
      localStorage.removeItem("from");
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (authCode !== "") {
      mutation.mutate();
    }
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9-]/g, "");
    if (sanitizedValue.length >= 6) {
      const truncatedValue = sanitizedValue.slice(0, 6);
      setAuthCode(truncatedValue);
    } else {
      setAuthCode(sanitizedValue);
    }
  };
  return (
    <div
      className="w-screen h-screen flex flex-col justify-center items-center gap-4 relative"
      style={{
        backgroundImage: 'url("../loginBG.png")',
        backgroundSize: "cover",
        backgroundPosition: "120px 120px",
      }}
    >
      <form
        action="#"
        onSubmit={handleSubmit}
        className="bg-[#1B1421] opacity-90  shadow-md shadow-[#0000001a] py-[30px] px-[50px] rounded-[20px] border-[0.5px] border-[#42338E] w-full max-w-[450px] flex flex-col justify-start items-start gap-3 relative z-10"
      >
        {/* logo */}
        <div className="w-full flex justify-center items-center">
          <Link
            className="w-16 h-16 "
            to="https://deepstrike.io/"
            target="_blank"
          >
            <img
              src="DeepStrike.webp"
              alt="DeepStrike"
              className="w-full h-full"
            />
          </Link>
        </div>
        <div className="w-full flex flex-col justify-start items-start gap-1">
          <h1 className="text-[#E3E5E8] text-2xl font-normal m-0">
            Multi-Factor Authentication
          </h1>
          <p className="text-[#93999F] text-sm font-normal m-0 ">
            Welcome back to DeepStrike 👋
          </p>
        </div>
        <div className="w-full flex flex-col items-center justify-start gap-1 mb-2">
          {mutation.isError && (
            <div className="w-full p-2.5 mb-3 bg-[#2D2023] border border-[#EB6461] rounded-xl text-sm text-[#e97270] flex flex-col items-start gap-2">
              <p>
                {mutation?.error?.response?.data?.errors?.message ??
                  "Somthing went wrrong"}
              </p>
            </div>
          )}
          <label className="w-full text-[#93999F] text-base font-normal m-0">
            Enter the code from your authenticator app
          </label>
          <input
            className={`w-full bg-[#1B1421]  text-[#e3e5e8] border border-[#42338E] outline-0 px-3 py-2 rounded-[0.625rem] focus:ring-0
            ${
              error ||
              (mutation.isError &&
                "border-[#EB6461] animate-wiggle text-red-500")
            }`}
            placeholder="Authentication code"
            type="text"
            onChange={handleChange}
            value={authCode}
            onBlur={() => {
              authCode === "" && setError(true);
            }}
          />
        </div>
        <button
          type="submit"
          className={`w-full inline-flex justify-center rounded-[0.625rem] transition-all duration-300  py-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
            error
              ? "bg-indigo-400 cursor-not-allowed"
              : "bg-indigo-700 hover:bg-indigo-600"
          }`}
          disabled={mutation.isLoading}
          onClick={handleSubmit}
        >
          {mutation.isLoading ? (
            <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
          ) : (
            "Log In"
          )}
        </button>
        <Link
          className="w-full text-center underline text-[#93999f] text-base"
          to="/2-factor-authentication-recovery"
        >
          Try another method
        </Link>
        <Link
          className="w-full flex justify-center items-center gap-2 text-center underline text-[#93999f] text-base"
          to="/login"
          onClick={() => {
            localStorage.clear();
            setEmail("");
            setPassword("");
          }}
        >
          Logout
          <LuLogOut />
        </Link>
      </form>
    </div>
  );
}
