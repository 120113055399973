import React, { useEffect, useRef, useState } from "react";
import {
  HiChevronDown,
  HiMagnifyingGlass,
  HiOutlineBackspace,
} from "react-icons/hi2";
import countries from "../../utils/countries.json";
import { useAuth } from "../../context/authProvider";

export default function Victims({ country, handleChange }) {
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [searchQuery, setSearchQuery] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const { setTableHeader } = useAuth();

  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = countries.filter((country) =>
      country.name.toLowerCase().includes(query)
    );

    setFilteredCountries(filtered);
  };

  const menuRef3 = useRef(null);
  const filterRef3 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (!filterRef3.current || !filterRef3.current.contains(event.target)) &&
        (!menuRef3.current || !menuRef3.current.contains(event.target))
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="relative h-full w-full xl:w-max border border-gray-500 rounded-lg bg-gray-500/10 sm:text-sm">
      <div
        className="w-full lg:w-max px-3 py-2 flex justify-center lg:justify-between items-center gap-2 text-white  h-full  cursor-pointer"
        onClick={() => {
          setTableHeader(3);
          setShowOptions(!showOptions);
        }}
        ref={filterRef3}
      >
        <h1 className="leading-[1.3] font-semibold text-indigo-500 ">
          <span className="text-white">Country: </span>
          {country ? country : countries[0].name}
        </h1>

        <HiChevronDown
          className={`duration-150 ease-in-out ${showOptions && "rotate-180"}`}
        />
      </div>

      <div
        className={`absolute max-h-[25.5vh] z-10 w-full lg:min-w-max left-0 top-11 lg:top-14 p-2 border border-gray-500 flex-col justify-start items-start bg-gray-800 rounded-lg text-white sm:text-sm ${
          showOptions ? "flex" : "hidden"
        }`}
        ref={menuRef3}
      >
        <div className="relative w-full mb-3 border border-gray-500 rounded-lg">
          <HiMagnifyingGlass
            className="pointer-events-none absolute inset-y-0 left-3 h-full w-5 text-gray-500"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="rounded-lg block h-full w-full border-0 bg-gray-500/10 pl-10 pr-0 text-white focus:ring-0 sm:text-sm"
            placeholder="Search..."
            type="search"
            name="search"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {searchQuery && (
            <HiOutlineBackspace
              className="cursor-pointer absolute inset-y-0 right-3 h-full w-5 text-indigo-500"
              aria-hidden="true"
              onClick={() => {
                setSearchQuery("");
                setFilteredCountries(countries);
              }}
            />
          )}
        </div>

        <div className="w-full min-w-fit overflow-y-auto">
          {filteredCountries.map((option, i) => (
            <button
              key={i}
              className="w-full flex items-center gap-3 p-3 border-0 bg-transparent hover:bg-slate-500/10"
              onClick={() => {
                handleChange(option.code);
                setShowOptions(false);
              }}
            >
              <div
                className={`w-5 h-5 rounded-full flex items-center justify-center border-[2px] ${
                  country === option.code
                    ? "bg-indigo-600 border-indigo-600"
                    : "bg-gray-700 border-gray-600"
                }`}
              >
                <div
                  className={`h-[8px] w-[8px] rounded-full ${
                    country === option.code ? "bg-indigo-950" : "bg-transparent"
                  }`}
                ></div>
              </div>
              <h1 className="text-sm font-medium m-0">{option.name}</h1>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
