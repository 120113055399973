import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiMagnifyingGlass } from "react-icons/hi2";
import { useAuth } from "../../context/authProvider";

export default function FilterBTN({ filterBTN, dateFilter, handleChange,route }) {
  const [showOptions, setShowOptions] = useState(false);
  const { setTableHeader } = useAuth();

  const menuRef2 = useRef(null);
  const filterRef2 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (!filterRef2.current || !filterRef2.current.contains(event.target)) &&
        (!menuRef2.current || !menuRef2.current.contains(event.target))
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="py-2 w-full lg:w-auto relative min-w-fit px-5 border border-gray-500 justify-center flex  items-center h-full bg-gray-500/10 rounded-lg text-white sm:text-sm">
      <button
        className="w-full h-full rounded-lg"
        onClick={() => {
          setTableHeader(2);
          setShowOptions(!showOptions);
        }}
        ref={filterRef2}
      >
        <h1 className="leading-[1.3] font-semibold">
          {filterBTN.name}
          <span className="ml-3">|</span>
          <span className="ml-3 text-indigo-500 ">
            {dateFilter=== null ? route === "users-credentials" ? "All time":"Recent"  : route=== "users-credentials" ?`Last ${(String(dateFilter).replace('-',''))} days`: dateFilter}
          </span>
          <HiChevronDown
            className={`ml-3 inline duration-150 ease-in-out ${
              showOptions && "rotate-180"
            }`}
          />
        </h1>
      </button>
      <div
        className={`absolute z-10 w-full lg:w-max left-0 top-10 lg:top-14 p-2 border border-gray-500 flex-col justify-start items-start bg-gray-800 rounded-lg text-white sm:text-sm ${
          showOptions ? "flex" : "hidden"
        }`}
        ref={menuRef2}
      >
        {/* <div className="relative w-full mb-3 border border-gray-500 rounded-lg">
          <HiMagnifyingGlass
            className="pointer-events-none absolute inset-y-0 left-3 h-full w-5 text-gray-500"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="rounded-lg block h-full w-full border-0 bg-gray-500/10 pl-10 pr-0 text-white focus:ring-0 sm:text-sm"
            placeholder="Search..."
            type="search"
            name="search"
          />
        </div> */}

        {filterBTN.options.map((option, i) => (
          <button
            key={i}
            className="w-full flex items-center gap-3 p-3 border-0 bg-transparent hover:bg-slate-500/10"
            onClick={() => {
              handleChange(option.value);
              // handleChange(option.value, option.name);
              setShowOptions(false);
            }}
          >
            <div
              className={`w-5 h-5 rounded-full flex items-center justify-center border-[2px] ${
                dateFilter === option.value
                  ? "bg-indigo-600 border-indigo-600"
                  : "bg-gray-700 border-gray-600"
              }`}
            >
              <div
                className={`h-[8px] w-[8px] rounded-full ${
                  // dateFilter === option.name || dateFilter === option.value
                  dateFilter === option.value
                    ? "bg-indigo-950"
                    : "bg-transparent"
                }`}
              ></div>
            </div>
            <h1 className="text-sm font-medium m-0">
              {option.value === null ? route === "users-credentials" ? "All time":"Recent"  : route=== "users-credentials" ?`Last ${(String(option.value).replace('-',''))} days`: option.value}
            </h1>
          </button>
        ))}
      </div>
    </div>
  );
}
