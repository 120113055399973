import { useState, useRef, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import updateUserInfo from "../../apis/mutation/updateUserInfo";
import SuccessModal from "../../components/SuccessModal";
import { PiCircleNotchBold } from "react-icons/pi";

export default function UserInfo({ data, queryKey }) {
  const [firstName, setFirstName] = useState(data.data.first_name);
  const [secondName, setSecondName] = useState(data.data.second_name);
  const [phoneNum, setPhoneNum] = useState(data.data.phone_number);
  const [email, setemail] = useState(data.data.email);
  const [jobTitle, setJobTitle] = useState(data.data.job_title);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const confirmButtonRef = useRef(null);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      return updateUserInfo(firstName, secondName, phoneNum, jobTitle);
    },
    onSuccess: (data) => {
      setShowSuccessMsg(!showSuccessMsg);
      setSuccessMsg(data?.message);
      queryClient.invalidateQueries(queryKey);
      const userData = JSON.parse(localStorage.getItem("user"));
      userData.first_name = firstName;
      userData.second_name = secondName;
      localStorage.setItem("user", JSON.stringify(userData));
      // refresh the page
      window.location.reload();
    },
  });

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && confirmButtonRef.current) {
        confirmButtonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      <div className="w-full pt-5 pb-8 flex flex-col items-start justify-start border-b border-gray-300/20 gap-3">
        {/* Form Inputs */}
        <div className="w-full flex flex-nowrap lg:flex-wrap flex-col lg:flex-row justify-start items-start gap-3">
          {/* First Name */}
          <div className="w-full lg:w-[45%]">
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              First Name
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-2 bg-transparent text-white shadow-sm ring-1 ring-inset ring-gray-400/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="First Name"
                name="firstName"
                value={firstName || ""}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          {/* Last Name */}
          <div className="w-full lg:w-[45%]">
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              Last Name
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-2 bg-transparent text-white shadow-sm ring-1 ring-inset ring-gray-400/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Last Name"
                name="secondName"
                value={secondName || ""}
                onChange={(e) => setSecondName(e.target.value)}
              />
            </div>
          </div>
          {/* Phone Number */}
          <div className="w-full lg:w-[45%]">
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              Phone Number
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-2 bg-transparent text-white shadow-sm ring-1 ring-inset ring-gray-400/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Phone Number"
                name="phone_number"
                value={phoneNum || ""}
                onChange={(e) => setPhoneNum(e.target.value)}
              />
            </div>
          </div>
          {/* Job Title */}
          <div className="w-full lg:w-[45%]">
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              Job Title
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-2 bg-transparent text-white shadow-sm ring-1 ring-inset ring-gray-400/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Job Title"
                name="job_title"
                value={jobTitle || ""}
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </div>
          </div>
          {/* Primary Email Address */}
          <div className="w-full lg:w-[92%]">
            <label
              htmlFor="text"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              Primary Email Address
            </label>
            <div className="mt-1.5 relative">
              <input
                type="email"
                className="block w-full rounded-md border-0 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-400/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 cursor-not-allowed opacity-50 bg-gray-700"
                placeholder="Primary Email Address"
                name="email"
                value={email || ""}
                disabled
              />
            </div>
          </div>

          <button
            type="button"
            className={`inline-flex sm:w-1/5 justify-center rounded-lg bg-indigo-600 px-4 py-3 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
              firstName === data.data.first_name &&
              secondName === data.data.second_name &&
              phoneNum === data.data.phone_number &&
              jobTitle === data.data.job_title
                ? "cursor-not-allowed  opacity-60"
                : "hover:bg-indigo-500"
            }`}
            onClick={() => mutation.mutate()}
            ref={confirmButtonRef}
            disabled={
              firstName === data.data.first_name &&
              secondName === data.data.second_name &&
              phoneNum === data.data.phone_number &&
              jobTitle === data.data.job_title
                ? true
                : false
            }
          >
            {mutation.isLoading ? (
              <PiCircleNotchBold className="text-xl font-semibold text-white animate-spin" />
            ) : (
              "Update"
            )}
          </button>
        </div>
      </div>

      <SuccessModal
        show={showSuccessMsg}
        setShow={setShowSuccessMsg}
        message={successMsg}
      />
    </>
  );
}
