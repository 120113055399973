import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { PiCircleNotchBold, PiEye, PiEyeClosed } from "react-icons/pi";
import SuccessModal from "../../components/SuccessModal";
import resetPassword from "../../apis/mutation/resetPassword";
import { decode } from "js-base64";
import ExpiredResetToken from "../ExpiredResetToken";

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get("token");
  const expiredAt = JSON.parse(decode(token));
  const newToken = expiredAt.token;

  const expiredDate = new Date(expiredAt.expired_at);

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const [validForm, setValidForm] = useState(false);

  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    has8digit: false,
  });
  const [newPasswordInput, setNewPasswordInput] = useState("");

  const [viewPassword, setViewPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [isValid, setIsValid] = useState({
    newPassword: true,
    confirmPassword: true,
  });
  const [passwordLvl, setPasswordLvl] = useState("");

  const resetPasswordBtnRef = useRef(null);

  const PasswordStrength = (newPassword) => {
    if (newPassword.length <= 7 || newPassword.length > 20) {
      return false;
    }

    if (!/[A-Z]/.test(newPassword)) {
      return false;
    }

    if (!/[a-z]/.test(newPassword)) {
      return false;
    }

    if (!/\d/.test(newPassword)) {
      return false;
    }

    // if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword)) {
    //   return false;
    // }

    return true;
  };

  const validatePassword = (newPassword) => {
    setValidate({
      hasLow: /[a-z]/.test(newPassword),
      hasCap: /[A-Z]/.test(newPassword),
      hasNumber: /\d/.test(newPassword),
      has8digit: newPassword.length >= 8,
      hasSpicialChar: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword),
    });
  };

  const [tokenError, setTokenError] = useState("");

  const changePasswordlvl = () => {
    if (
      validate.has8digit &&
      validate.hasLow &&
      validate.hasCap &&
      validate.hasNumber &&
      validate.hasSpicialChar
    ) {
      setPasswordLvl("Strong Password");
    } else if (validate.has8digit && validate.hasLow && validate.hasCap) {
      setPasswordLvl("Medium Password");
    } else if (validate.has8digit) {
      setPasswordLvl(
        "Weak Password: Password must contain lowercase, uppercase, numbers, and special characters"
      );
    } else {
      setPasswordLvl("Password must be at least 8 characters");
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));

    if (name === "newPassword") {
      setNewPasswordInput(value);
      validatePassword(value);
      changePasswordlvl();
    }

    setIsValid((prevIsValid) => ({
      ...prevIsValid,
      newPassword: true,
      confirmPassword: true,
    }));
  };

  const isPasswordValid = (newPassword, confirmPassword) => {
    if (newPassword !== confirmPassword || !PasswordStrength(newPassword)) {
      return false;
    } else {
      return true;
    }
  };

  const { newPassword, confirmPassword } = password;

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      return resetPassword({ newToken, newPassword, confirmPassword });
    },
    onSuccess: () => {
      setShowSuccessMsg(!showSuccessMsg);
      queryClient.invalidateQueries("accept_Invitation");
      setPassword({
        newPassword: "",
        confirmPassword: "",
      });
      setNewPasswordInput("");
      setPasswordLvl("");
      setTokenError("");
      navigate("/login");
    },
    onError: (error) => {
      setTokenError(error.response.data.message);
    },
  });

  const handleSubmit = () => {
    const isPasswordValidResult = isPasswordValid(newPassword, confirmPassword);
    setIsValid({
      newPassword: isPasswordValidResult,
      confirmPassword: isPasswordValidResult,
    });
    if (isPasswordValidResult) {
      mutation.mutate();
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && resetPasswordBtnRef.current) {
        resetPasswordBtnRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    if (isPasswordValid(newPasswordInput, password.confirmPassword)) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [passwordLvl, newPasswordInput, password.confirmPassword]);

  return (
    <>
      {expiredDate.getTime() < new Date().getTime() ? (
        <ExpiredResetToken />
      ) : (
        <div
          className="w-screen h-screen bg-[#1c1f22] flex flex-col justify-center items-center gap-4 relative"
          style={{
            backgroundImage: 'url("../loginBG.png")',
            backgroundSize: "cover",
            backgroundPosition: "120px 120px",
          }}
        >
          <div className="w-[300px] h-[320px] absolute  bg-[#3a2b47] blur-3xl z-[1]" />

          <form className="bg-[#1B1421] opacity-90  shadow-md shadow-[#0000001a] py-[30px] px-[50px] rounded-[20px] border-[0.5px] border-[#42338E] w-full max-w-[450px] flex flex-col justify-start items-start gap-3 relative z-10">
            {/* logo */}
            <div className="w-full flex justify-center items-center">
              <Link
                className="w-16 h-16 "
                to="https://deepstrike.io/"
                target="_blank"
              >
                <img
                  src="DeepStrike.webp"
                  alt="DeepStrike"
                  className="w-full h-full"
                />
              </Link>
            </div>

            <div className="w-full flex flex-col justify-start items-start gap-1">
              <h1 className="text-[#E3E5E8] text-2xl font-normal m-0">
                Reset Password
              </h1>
              <p className="text-[#93999F] text-sm font-normal m-0 "></p>
            </div>

            <div className="w-full flex flex-col items-center justify-start gap-1 mb-2">
              <div className="w-full flex flex-col items-start justify-center gap-2">
                <label
                  htmlFor="text"
                  className="block text-gray-400 text-sm font-medium leading-6 "
                >
                  Email
                </label>
                <input
                  className="w-full bg-[#1B1421] text-[#e3e5e8] border border-[#42338E] outline-0 px-3 py-2 rounded-[0.625rem] focus:ring-0 cursor-not-allowed opacity-50"
                  placeholder="Enter your email"
                  type="text"
                  value={searchParams.get("email") || ""}
                  disabled
                />
              </div>

              <div className="w-full">
                <div
                  className={`w-full flex items-center gap-2 text-sm font-medium leading-6 ${
                    isValid.newPassword ? "text-gray-400" : "text-red-400"
                  }`}
                >
                  <label htmlFor="text" className="block">
                    New Password
                  </label>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <div
                    className={`mt-1.5 w-full relative flex items-center gap-2 ${
                      isValid.newPassword
                        ? "text-white"
                        : "text-red-400 animate-wiggle"
                    }`}
                  >
                    <input
                      type={viewPassword.newPassword ? "text" : "password"}
                      className={`block w-full rounded-md border-0 pr-10 py-2 bg-transparent shadow-sm ring-1 ring-insetplaceholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#735bf0] sm:text-sm sm:leading-6 ${
                        isValid.newPassword
                          ? "ring-[#735bf0] "
                          : "ring-red-400  "
                      }`}
                      placeholder="New Password"
                      name="newPassword"
                      value={newPasswordInput || ""}
                      onChange={handlePasswordChange}
                      onBlur={() => {
                        const isValidPassword =
                          PasswordStrength(newPasswordInput);
                        setIsValid((prevIsValid) => ({
                          ...prevIsValid,
                          newPassword: isValidPassword,
                        }));
                        password.confirmPassword !== password.newPassword &&
                          password.confirmPassword !== "" &&
                          setIsValid((prevIsValid) => ({
                            ...prevIsValid,
                            newPassword: false,
                          }));

                        changePasswordlvl();
                      }}
                    />

                    <div className="absolute right-3 duration-150">
                      {viewPassword.newPassword ? (
                        <PiEye
                          className="cursor-pointer select-none"
                          onClick={() =>
                            setViewPassword((prevState) => ({
                              ...prevState,
                              newPassword: false,
                            }))
                          }
                        />
                      ) : (
                        <PiEyeClosed
                          className="cursor-pointer select-none"
                          onClick={() =>
                            setViewPassword((prevState) => ({
                              ...prevState,
                              newPassword: true,
                            }))
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className="w-full flex flex-col ">
                    <div className="w-full  bg-[#241a2c] rounded-lg overflow-hidden">
                      <div
                        className={`block h-2 duration-150 ease-in-out ${
                          passwordLvl === "Strong Password"
                            ? "w-full bg-green-500"
                            : passwordLvl === "Medium Password"
                            ? "w-1/2 bg-orange-500"
                            : passwordLvl ===
                                "Weak Password: Password must be atleast 8 litter [a-z A-Z], Contain a numbers and spicial characters" ||
                              passwordLvl ===
                                "Password must be at least 8 characters"
                            ? "w-1/4 bg-red-500"
                            : "w-0"
                        }`}
                      />
                    </div>
                    <p className="text-gray-400 text-xs">{passwordLvl}</p>
                  </div>
                </div>

                <div
                  className={`w-full text-sm text-red-400 animate-wiggle 
                  ${
                    isValid.newPassword && mutation.isError === false
                      ? "hidden"
                      : "block"
                  }
                  `}
                >
                  <p>
                    {password.newPassword === ""
                      ? "Password cannot be empty"
                      : password.confirmPassword !== password.newPassword
                      ? "Passwords do not match"
                      : mutation.isLoading === false && mutation.isError
                      ? tokenError
                      : "Invalid password"}
                  </p>
                </div>
              </div>
              {/* Confirm Password */}
              <div className="w-full">
                <div
                  className={`w-full flex items-center gap-2 text-sm font-medium leading-6 ${
                    isValid.confirmPassword ? "text-gray-400" : "text-red-400"
                  }`}
                >
                  <label htmlFor="text" className="block">
                    Confirm Password
                  </label>
                </div>
                <div
                  className={`mt-1.5 w-full relative flex items-center ${
                    isValid.confirmPassword
                      ? "text-white"
                      : "text-red-400 animate-wiggle"
                  }`}
                >
                  <input
                    type={viewPassword.confirmPassword ? "text" : "password"}
                    className={`block w-full rounded-md border-0 pr-10 py-2 bg-transparent shadow-sm ring-1 ring-insetplaceholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#42338E] sm:text-sm sm:leading-6 ${
                      isValid.confirmPassword
                        ? "ring-[#42338E] "
                        : "ring-red-400  "
                    }`}
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={password.confirmPassword || ""}
                    onChange={handlePasswordChange}
                    onBlur={() => {
                      password.confirmPassword !== password.newPassword &&
                        setIsValid((prevIsValid) => ({
                          ...prevIsValid,
                          confirmPassword: false,
                        }));
                    }}
                  />

                  <div className="absolute right-3  duration-150">
                    {viewPassword.confirmPassword ? (
                      <PiEye
                        className="cursor-pointer select-none"
                        onClick={() =>
                          setViewPassword((prevState) => ({
                            ...prevState,
                            confirmPassword: false,
                          }))
                        }
                      />
                    ) : (
                      <PiEyeClosed
                        className="cursor-pointer select-none"
                        onClick={() =>
                          setViewPassword((prevState) => ({
                            ...prevState,
                            confirmPassword: true,
                          }))
                        }
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`w-full text-sm text-red-400 animate-wiggle
                 ${isValid.confirmPassword ? "hidden" : "block"}`}
                >
                  <p>
                    {password.confirmPassword === ""
                      ? "Password cannot be empty"
                      : password.confirmPassword !== password.newPassword
                      ? "Passwords do not match"
                      : "Invalid password"}
                  </p>
                </div>
              </div>
            </div>

            <button
              type="button"
              className={`w-full inline-flex justify-center rounded-[0.625rem] transition-all duration-300 bg-indigo-700  py-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                !validForm
                  ? "cursor-not-allowed opacity-60"
                  : "hover:bg-indigo-500"
              }`}
              disabled={!validForm}
              onClick={() => {
                handleSubmit();
              }}
              ref={resetPasswordBtnRef}
            >
              {mutation.isLoading ? (
                <PiCircleNotchBold className="animate-spin" />
              ) : (
                "Change Password"
              )}
            </button>
          </form>

          <div>
            <p className="text-[#93999f] text-sm">
              Do you have an account? <Link to="/login">Log In</Link>
            </p>
          </div>
          {showSuccessMsg && (
            <SuccessModal
              show={showSuccessMsg}
              setShow={setShowSuccessMsg}
              message="Process has been updated succesfully"
            />
          )}
        </div>
      )}
    </>
  );
}
