import React, { useState } from "react";
import Table from "../../components/Table";
import Loading from "../../components/Loading";
import getAvailableDomains from "../../apis/query/availableDomains";
import { useQuery } from "react-query";
import Card from "../../components/PageCards";
import SuccessModal from "../../components/SuccessModal";

export default function Breaches() {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["breaches-availableDomains"],
    queryFn: () => getAvailableDomains({ domainType: 1 }),
  });

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="p-5 w-full flex flex-col gap-11 lg:w-[125%] 2xl:w-full 2xl:transform-none transform lg:scale-[0.80] 2xl:scale-100 origin-top-left pl-5 2xl:mt-0">
          <div>
            <h1 className="text-2xl text-white font-bold my-5 ">
              Data Breaches
            </h1>
            <Card
              cardRoute="breaches-meta"
              icons={[
                "card-phishing.svg",
                "sql-injection.svg",
                "password-phishing.svg",
              ]}
              dataRoute="leakedBreaches-meta"
              titles={[
                { name: "Breached Records", value: "breached_records" },
                { name: "Breach Sources", value: "total_sources" },
                { name: "Unique Accounts", value: "unique_emails" },
              ]}
            />
          </div>

          <div className="w-full bg-gray-500/10 border border-gray-400/10 rounded-lg mb-5">
            <Table
              queryKey={"breaches"}
              route={"breaches"}
              resKey="leakedBreaches"
              pagination={true}
              select={false}
              tableHeader={true}
              rowAction={false}
              filterDomains={true}
              exportFilter={false}
              actionFilter={false}
              resizableCols={true}
              manageColsFilter={true}
              tableTitle={true}
              tableDesc="You can search on the breached data by employee email address, phone number, username"
              showSuccessMsg={showSuccessMsg}
              setShowSuccessMsg={setShowSuccessMsg}
              successMsg={successMsg}
              setSuccessMsg={setSuccessMsg}
              copyCell={["email", "username", "password", "hashed_password"]}
              filterBTN={{
                name: "Date",
                options: [
                  { name: "recent", value: "recent" },
                  { name: "2023", value: "2023" },
                  { name: "2022", value: "2022" },
                  { name: "2021", value: "2021" },
                ],
              }}
              // status={["Not completed yet", "Completed"]}
              status={["Open", "Fixed"]}
              victimsCountry={false}
              companyBTN="Domains"
              searchBTN={{ options: ["email", "url"] }}
              columns={[
                "email",
                "username",
                "name",
                "password",
                "hashed_password",
                "database_name",
                "ip_address",
                "phone",
              ]}
              tHead={[
                "Email",
                "Username",
                "Name",
                "Password",
                "Hashed password",
                "LeakSource",
                "IP address",
                "Phone number",
              ]}
              companies={data}
            />
          </div>
        </div>
      )}
      {showSuccessMsg && (
        <SuccessModal
          show={showSuccessMsg}
          setShow={setShowSuccessMsg}
          message={successMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}
    </>
  );
}
