import React, { useEffect, useRef, useState } from "react";
import {
  HiMagnifyingGlass,
  HiOutlineArrowLeftCircle,
  HiOutlineBackspace,
} from "react-icons/hi2";
import { useAuth } from "../../context/authProvider";

export default function SearchBTN({
  searchType,
  setSearchType,
  handleChange,
  searchBTN,
  search,
  setSearch,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const menuRef1 = useRef(null);
  const filterRef1 = useRef(null);

  useEffect(() => {
    const handleClickOutside1 = (event) => {
      if (
        (!filterRef1.current || !filterRef1.current.contains(event.target)) &&
        (!menuRef1.current || !menuRef1.current.contains(event.target))
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside1);

    return () => {
      document.removeEventListener("click", handleClickOutside1);
    };
  }, []);
  return (
    <div className="relative h-full w-full lg:min-w-[20vw] xl:min-w-[22vw] border border-gray-500 rounded-lg">
      {search === "" ? (
        <HiMagnifyingGlass
          className=" absolute inset-y-0 left-3 h-full w-5 text-gray-500"
          aria-hidden="true"
        />
      ) : (
        <>
          <HiOutlineArrowLeftCircle
            className="cursor-pointer absolute text-indigo-500 inset-y-0 left-3 h-full w-5 "
            aria-hidden="true"
            onClick={() => handleChange(search)}
          />
          <HiOutlineBackspace
            className="cursor-pointer absolute text-indigo-500 inset-y-0 right-3 h-full w-5 "
            aria-hidden="true"
            onClick={() => handleChange("")}
          />
        </>
      )}
      <input
        id="search-field"
        className="rounded-xl block h-full w-full border-0 bg-gray-500/10 pl-10 pr-[37%] lg:pr-[40%] xl:pr-[8vw] 2xl:pr-[7vw] text-white focus:ring-0 sm:text-sm"
        placeholder="Search..."
        type="search"
        name="search"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleChange(search);
          }
        }}
      />
      {/* <div
        className="w-max px-3 py-2 flex justify-between items-center gap-2 text-white border-l border-gray-500 absolute inset-y-0 right-0 h-full  cursor-pointer"
        onClick={() => {
          setTableHeader(1);
          setShowOptions(!showOptions);
        }}
        ref={filterRef1}
      >
        <h1>{searchType}</h1>
        <HiChevronDown
          className={`duration-150 ease-in-out ${showOptions && "rotate-180"}`}
        />
      </div> */}

      <div
        className={`absolute z-10 w-full lg:w-[50%] min-w-fit right-0 lg:-right-20 top-10 lg:top-14 p-2 border border-gray-500 flex-col justify-start items-start bg-gray-800 rounded-lg text-white sm:text-sm ${
          showOptions ? "flex" : "hidden"
        }`}
        ref={menuRef1}
      >
        {searchBTN.options.map((option, i) => (
          <button
            key={i}
            className="w-full flex items-center gap-3 p-3 border-0 bg-transparent hover:bg-slate-500/10"
            onClick={() => {
              setSearchType(option);
              setShowOptions(false);
            }}
          >
            <div
              className={`w-5 h-5 rounded-full flex items-center justify-center border-[2px] ${
                searchType === option
                  ? "bg-indigo-600 border-indigo-600"
                  : "bg-gray-700 border-gray-600"
              }`}
            >
              <div
                className={`h-[8px] w-[8px] rounded-circle ${
                  searchType === option ? "bg-indigo-950" : "bg-transparent"
                }`}
              ></div>
            </div>
            <h1 className="text-sm font-medium m-0">{option}</h1>
          </button>
        ))}
      </div>
    </div>
  );
}
