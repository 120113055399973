import React, { useEffect, useRef, useState } from "react";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { HiChevronDown } from "react-icons/hi2";
import { useAuth } from "../../context/authProvider";
import exportFile from "../../apis/query/exportFile";

export default function Export({
  exportRoute,
  exportType,
  setSuccessMsg,
  show,
  setShow,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const { setTableHeader } = useAuth();

  const options = [
    { name: "Export as JSON", format: "json", contentType: "application/json" },
    { name: "Export as CSV", format: "csv", contentType: "text/csv" },
  ];

  const menuRef6 = useRef(null);
  const filterRef6 = useRef(null);

  const handleExport = async (format) => {
    try {
      const response = await exportFile({
        format: format,
        route: exportRoute,
        type: exportType,
      });

      setSuccessMsg(response.data.message);
      setShow(!show);
    } catch (error) {
      console.error("Error while exporting: ", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (!filterRef6.current || !filterRef6.current.contains(event.target)) &&
        (!menuRef6.current || !menuRef6.current.contains(event.target))
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative h-full w-full lg:w-max border border-gray-500 rounded-lg bg-gray-500/10 sm:text-sm"
      ref={filterRef6}
    >
      <div
        className="w-full lg:w-max px-3 py-2 flex justify-center lg:justify-between items-center gap-2 text-white  h-full  cursor-pointer"
        onClick={() => {
          setTableHeader(6);
          setShowOptions(!showOptions);
        }}
      >
        <FaArrowUpFromBracket />
        <h1 className="leading-[1.3] font-semibold">Export</h1>
        <HiChevronDown
          className={`duration-150 ease-in-out ${showOptions && "rotate-180"}`}
        />
      </div>

      <div
        className={`absolute z-10 w-full lg:w-[200%] left-[-40px] lg:-left-auto top-11 lg:top-14 p-2 border border-gray-500 flex-col justify-start items-start bg-gray-800 rounded-lg text-white sm:text-sm ${
          showOptions ? "flex" : "hidden"
        }`}
        ref={menuRef6}
      >
        {options.map((option, i) => (
          <button
            key={i}
            className="w-full flex items-center gap-3 p-3 border-0 bg-transparent hover:bg-slate-500/10"
            onClick={() => {
              setShowOptions(false);
              handleExport(option.format, option.contentType);
            }}
          >
            <h1 className="text-sm font-medium m-0">{option.name}</h1>
          </button>
        ))}
      </div>
    </div>
  );
}
