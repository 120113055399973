import React from "react";
import { HiBars3, HiMagnifyingGlass } from "react-icons/hi2";
import { useAuth } from "../../context/authProvider";

export default function NavBar() {
  const { setSidebarOpen } = useAuth();

  return (
    <div className="sticky top-0 z-40 shrink-0  w-full">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-white xl:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <HiBars3 className="h-5 w-5 m-4" aria-hidden="true" />
      </button>

      {/* <div className="flex items-center justify-center flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <h1 className="text-2xl font-semibold -ml-5">DeepStrike</h1>
      </div> */}
    </div>
  );
}
