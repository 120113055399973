import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { useAuth } from "../../context/authProvider";

export default function Company({
  company,
  handleChange,
  options,
  companyBTN,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const { setTableHeader } = useAuth();

  const menuRef4 = useRef(null);
  const filterRef4 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (!filterRef4.current || !filterRef4.current.contains(event.target)) &&
        (!menuRef4.current || !menuRef4.current.contains(event.target))
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative h-full w-full lg:w-max border border-gray-500 rounded-lg bg-gray-500/10 sm:text-sm"
      ref={filterRef4}
    >
      <div
        className="w-full lg:w-max px-3 py-2 flex justify-center lg:justify-between items-center gap-2 text-white  h-full  cursor-pointer"
        onClick={() => {
          setTableHeader(4);
          setShowOptions(!showOptions);
        }}
      >
        <h1 className="leading-[1.3] font-semibold text-indigo-500 ">
          <span className="text-white">{companyBTN}: </span>
          {company === null ? options[0]?.domain_name : company?.domain_name}
        </h1>
        <HiChevronDown
          className={`duration-150 ease-in-out ${showOptions && "rotate-180"}`}
        />
      </div>

      <div
        className={`absolute z-10 w-full lg:min-w-fit left-0 top-11 lg:top-14 p-2 border border-gray-500 flex-col justify-start items-start bg-gray-800 rounded-lg text-white sm:text-sm ${
          showOptions ? "flex" : "hidden"
        }`}
        ref={menuRef4}
      >
        {options.map((option, i) => {
          const isActive =
            company === null && i === 0
              ? true
              : company?.domain_name === option?.domain_name;
          return (
            <button
              key={i}
              className="w-full flex items-center gap-3 p-3 border-0 bg-transparent hover:bg-slate-500/10"
              onClick={() => {
                handleChange(option);
                setShowOptions(false);
              }}
            >
              <div
                className={`w-5 h-5 rounded-full flex items-center justify-center border-[2px] ${
                  isActive
                    ? "bg-indigo-600 border-indigo-600"
                    : "bg-gray-700 border-gray-600"
                }`}
              >
                <div
                  className={`h-[8px] w-[8px] rounded-full ${
                    isActive ? "bg-indigo-950" : "bg-transparent"
                  }`}
                ></div>
              </div>
              <h1 className="text-sm font-medium m-0">{option?.domain_name}</h1>
            </button>
          );
        })}
      </div>
    </div>
  );
}
