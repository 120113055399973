import instance from "../../utils/axios";

const GenerateAPI = async () => {
  try {
    const res = await instance.post("organization/api-key");
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default GenerateAPI;
